import React, { useContext, useEffect, useRef } from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { showModal2 } from '../slices/modalSlice';
import { changeRoomId, setNameBesetzt, setRaumGefunden } from '../slices/busfahrerSlice';
import { SocketContext } from '../context/socket';
import { BrowserView, MobileView } from 'react-device-detect';



const SpielraumModalBusfahrer2 = () => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const { modal2 } = useSelector(state => state.modal)
  const { username, roomId, nameBesetzt, raumGefunden } = useSelector((state) => state.busfahrer)
  const textInput = useRef()

  const raumBeitreten = (roomId) => {
    const data = {
      roomId: roomId,
      username: username,
    }
    socket.emit('joinRoomBusfahrer', data)
  }

  useEffect(() => {
    socket.on('keinRaumGefundenBusfahrer', () => {
      dispatch(setRaumGefunden(false))
      dispatch(changeRoomId(''))
    })
    socket.on('nameBesetztBusfahrer', () => {
      dispatch(setNameBesetzt(true))
      dispatch(changeRoomId(''))
    })

    socket.on('closeModalBusfahrer', () => {
      dispatch(showModal2())
    })
  }, [socket, dispatch]);

  return (
    <Modal
      show={modal2}
      onShow={() => { textInput.current.focus() }}
      onHide={() => dispatch(showModal2())}
      centered
    >
      <BrowserView>
        <Modal.Header closeButton>
          <Modal.Title>
            Raum beitreten
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className=''>
              <Form.Control
                ref={textInput}
                type="text"
                value={roomId}
                onChange={(e) => {
                  dispatch(changeRoomId(e.target.value))
                  if (e.target.value.length > 0) {
                    dispatch(setNameBesetzt(false))
                  }
                }}
                placeholder="Raum-ID eingeben" />
              <Form.Text hidden={!nameBesetzt} className="text-danger text-center">
                Ein anderer Spieler im Raum hat bereits den gleichen Usernamen.
              </Form.Text>
              <Form.Text hidden={raumGefunden} className="text-danger text-center">
                Es wurde kein Raum mit dieser Raum-ID gefunden.
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!(roomId.length > 0)}
            onClick={() => raumBeitreten(roomId)}
            className='btn btn-bg-primary'>
            Raum beitreten
          </Button>
        </Modal.Footer>
      </BrowserView>
      
      <MobileView>
        <Modal.Header closeButton>
          <Modal.Title>
            Raum beitreten
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className=''>
              <Form.Control
                ref={textInput}
                type="text"
                value={roomId}
                onChange={(e) => {
                  dispatch(changeRoomId(e.target.value))
                  if (e.target.value.length > 0) {
                    dispatch(setNameBesetzt(false))
                  }
                }}
                placeholder="Raum-ID eingeben" />
              <Form.Text hidden={!nameBesetzt} className="text-danger text-center">
                Ein anderer Spieler im Raum hat bereits den gleichen Usernamen.
              </Form.Text>
              <Form.Text hidden={raumGefunden} className="text-danger text-center">
                Es wurde kein Raum mit dieser Raum-ID gefunden.
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!(roomId.length > 0)}
            onTouchStart={() => raumBeitreten(roomId)}
            className='btn btn-bg-primary'>
            Raum beitreten
          </Button>
        </Modal.Footer>
      </MobileView>
    </Modal>
  )
}
export default SpielraumModalBusfahrer2