import { createSlice } from '@reduxjs/toolkit';
import Herz2 from '../poker-qr/2Herz.svg'
import Herz3 from '../poker-qr/3Herz.svg'
import Herz4 from '../poker-qr/4Herz.svg'
import Herz5 from '../poker-qr/5Herz.svg'
import Herz6 from '../poker-qr/6Herz.svg'
import Herz7 from '../poker-qr/7Herz.svg'
import Herz8 from '../poker-qr/8Herz.svg'
import Herz9 from '../poker-qr/9Herz.svg'
import Herz10 from '../poker-qr/10Herz.svg'
import HerzJ from '../poker-qr/JHerz.svg'
import HerzQ from '../poker-qr/QHerz.svg'
import HerzK from '../poker-qr/KHerz.svg'
import HerzA from '../poker-qr/AHerz.svg'
import Karo2 from '../poker-qr/2Karo.svg'
import Karo3 from '../poker-qr/3Karo.svg'
import Karo4 from '../poker-qr/4Karo.svg'
import Karo5 from '../poker-qr/5Karo.svg'
import Karo6 from '../poker-qr/6Karo.svg'
import Karo7 from '../poker-qr/7Karo.svg'
import Karo8 from '../poker-qr/8Karo.svg'
import Karo9 from '../poker-qr/9Karo.svg'
import Karo10 from '../poker-qr/10Karo.svg'
import KaroJ from '../poker-qr/JKaro.svg'
import KaroQ from '../poker-qr/QKaro.svg'
import KaroK from '../poker-qr/KKaro.svg'
import KaroA from '../poker-qr/AKaro.svg'
import Kreuz2 from '../poker-qr/2Kreuz.svg'
import Kreuz3 from '../poker-qr/3Kreuz.svg'
import Kreuz4 from '../poker-qr/4Kreuz.svg'
import Kreuz5 from '../poker-qr/5Kreuz.svg'
import Kreuz6 from '../poker-qr/6Kreuz.svg'
import Kreuz7 from '../poker-qr/7Kreuz.svg'
import Kreuz8 from '../poker-qr/8Kreuz.svg'
import Kreuz9 from '../poker-qr/9Kreuz.svg'
import Kreuz10 from '../poker-qr/10Kreuz.svg'
import KreuzJ from '../poker-qr/JKreuz.svg'
import KreuzQ from '../poker-qr/QKreuz.svg'
import KreuzK from '../poker-qr/KKreuz.svg'
import KreuzA from '../poker-qr/AKreuz.svg'
import Pik2 from '../poker-qr/2Pik.svg'
import Pik3 from '../poker-qr/3Pik.svg'
import Pik4 from '../poker-qr/4Pik.svg'
import Pik5 from '../poker-qr/5Pik.svg'
import Pik6 from '../poker-qr/6Pik.svg'
import Pik7 from '../poker-qr/7Pik.svg'
import Pik8 from '../poker-qr/8Pik.svg'
import Pik9 from '../poker-qr/9Pik.svg'
import Pik10 from '../poker-qr/10Pik.svg'
import PikJ from '../poker-qr/JPik.svg'
import PikQ from '../poker-qr/QPik.svg'
import PikK from '../poker-qr/KPik.svg'
import PikA from '../poker-qr/APik.svg'

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array
}

const initialState = {
  status: "idle",
  number: 0,
  deck: [
    {card: Herz2, value: 2}, {card: Herz3, value: 3}, {card: Herz4, value: 4}, {card: Herz5, value: 5}, {card: Herz6, value: 6}, {card: Herz7, value: 7}, {card: Herz8, value: 8}, {card: Herz9, value: 9}, {card: Herz10, value: 10}, {card: HerzJ, value: 11}, {card: HerzQ, value: 12}, {card: HerzK, value: 13}, {card: HerzA, value: 14},
    {card: Karo2, value: 2}, {card: Karo3, value: 3}, {card: Karo4, value: 4}, {card: Karo5, value: 5}, {card: Karo6, value: 6}, {card: Karo7, value: 7}, {card: Karo8, value: 8}, {card: Karo9, value: 9}, {card: Karo10, value: 10}, {card: KaroJ, value: 11}, {card: KaroQ, value: 12}, {card: KaroK, value: 13}, {card: KaroA, value: 14},
    {card: Kreuz2, value: 2}, {card: Kreuz3, value: 3}, {card: Kreuz4, value: 4}, {card: Kreuz5, value: 5}, {card: Kreuz6, value: 6}, {card: Kreuz7, value: 7}, {card: Kreuz8, value: 8}, {card: Kreuz9, value: 9}, {card: Kreuz10, value: 10}, {card: KreuzJ, value: 11}, {card: KreuzQ, value: 12}, {card: KreuzK, value: 13}, {card: KreuzA, value: 14},
    {card: Pik2, value: 2}, {card: Pik3, value: 3}, {card: Pik4, value: 4}, {card: Pik5, value: 5}, {card: Pik6, value: 6}, {card: Pik7, value: 7}, {card: Pik8, value: 8}, {card: Pik9, value: 9}, {card: Pik10, value: 10}, {card: PikJ, value: 11}, {card: PikQ, value: 12}, {card: PikK, value: 13}, {card: PikA, value: 14}
  ],
  currentCard: {},
  nextCard: {},
  correctInRow: 0,
  checkText: "",
  check: undefined
}



export const cardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    getRandomDeck(state, action) {
      state.status = "successful"
      state.deck = shuffleArray(state.deck)
      state.number = initialState.number
      state.currentCard = state.deck[0]
      state.nextCard = state.deck[1]
      state.correctInRow = initialState.number
      state.check = initialState.check
    },
    getRandomDeck2(state, action) {
      state.status = "successful"
      state.deck = shuffleArray(state.deck)
      state.number = initialState.number
      state.currentCard = state.deck[0]
      state.nextCard = state.deck[1]
      state.check = initialState.check
    },
    setRandomCard(state, action) {
      state.number = state.number + 1
      state.currentCard = state.deck[state.number]
      state.nextCard = state.number < initialState.deck.length ? state.deck[state.number + 1] : {}
    },
    checkHoeher(state, action) {
      if (action.payload.nextCard.value > action.payload.currentCard.value) {
        state.check = true
        state.correctInRow = state.correctInRow + 1
      }
      else {
        state.check = false
        state.correctInRow = initialState.correctInRow
      }
    },
    checkGleich(state, action) {
      if (action.payload.nextCard.value === action.payload.currentCard.value) {
        state.check = true
        state.correctInRow = state.correctInRow + 1
      }
      else {
        state.check = false
        state.correctInRow = initialState.correctInRow
      }
    },
    checkTiefer(state, action) {
      if (action.payload.nextCard.value < action.payload.currentCard.value) {
        state.check = true
        state.correctInRow = state.correctInRow + 1
      }
      else {
        state.check = false
        state.correctInRow = initialState.correctInRow
      }
    },
    resetCorrectInRow(state, action) {
      state.correctInRow = initialState.correctInRow
    }
  },
})

export const {
  getRandomDeck,
  getRandomDeck2,
  setRandomCard,
  checkHoeher,
  checkGleich,
  checkTiefer,
  resetCorrectInRow
} = cardSlice.actions

export default cardSlice.reducer