import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { showModal6 } from '../slices/modalSlice';
// import { SocketContext } from '../context/socket';
import { BrowserView, MobileView } from 'react-device-detect';
import { changePhase } from '../slices/busfahrerSlice';
import { getRandomDeck } from '../slices/cardSlice';

const SpielraumModalBusfahrer3 = () => {
  // const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const { modal6 } = useSelector(state => state.modal)
  const { username, busfahrer, roomBusfahrer } = useSelector((state) => state.busfahrer)

  const zuHoeherTiefer = () => {
    dispatch(getRandomDeck())
    dispatch(showModal6(false))
    dispatch(changePhase(3))
  }

  const zumStart = () => {
    dispatch(showModal6(false))
    dispatch(changePhase(1))
  }

  return (
    <div>
      <Modal
        show={modal6}
        centered
      >
        <BrowserView>
          <Modal.Header>
          </Modal.Header>
          <Modal.Body>
            {busfahrer} ist Busfahrer!
          </Modal.Body>
          <Modal.Footer>
            <Button
              hidden={roomBusfahrer.users[0].username !== username || roomBusfahrer.users[0].username === ''}
              onClick={() => zuHoeherTiefer()}
              className='btn btn-bg-primary'>
              Busfahren
            </Button>
            <Button
              hidden={roomBusfahrer.users[0].username === username || roomBusfahrer.users[0].username === ''}
              onClick={() => zumStart()}
              className='btn btn-bg-primary'>
              Zum Start
            </Button>
          </Modal.Footer>
        </BrowserView>

        <MobileView>
          <Modal.Header>
          </Modal.Header>
          <Modal.Body>
            {busfahrer} ist Busfahrer!
          </Modal.Body>
          <Modal.Footer>
            <Button
              hidden={roomBusfahrer.users[0].username !== username || roomBusfahrer.users[0].username === ''}
              onTouchStart={() => zuHoeherTiefer()}
              className='btn btn-bg-primary'>
              Busfahren
            </Button>
            <Button
              hidden={roomBusfahrer.users[0].username === username || roomBusfahrer.users[0].username === ''}
              onTouchStart={() => zumStart()}
              className='btn btn-bg-primary'>
              Zum Start
            </Button>
          </Modal.Footer>
        </MobileView>
      </Modal>
    </div>
  )
}
export default SpielraumModalBusfahrer3