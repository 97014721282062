import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';

function Datenschutz() {
  return (
    <Container className="col col-8">
      <Row className="pt-3">
        <Col>
          <h1 className="adsimple-311891325">Datenschutzerklärung</h1>
          <h2>Inhaltsverzeichnis</h2>
          <ul>
            <li>
              <a href="#einleitung-ueberblick">Einleitung und Überblick</a>
            </li>
            <li>
              <a href="#anwendungsbereich">Anwendungsbereich</a>
            </li>
            <li>
              <a href="#rechtsgrundlagen">Rechtsgrundlagen</a>
            </li>
            <li>
              <a href="#kontaktdaten-verantwortliche">Kontaktdaten des Verantwortlichen</a>
            </li>
            <li>
              <a href="#speicherdauer">Speicherdauer</a>
            </li>
            <li>
              <a href="#rechte-dsgvo">Rechte laut Datenschutz-Grundverordnung</a>
            </li>
            <li>
              <a href="#kommunikation">Kommunikation</a>
            </li>
            <li>
              <a href="#cookies">Cookies</a>
            </li>
            <li>
              <a href="#webhosting">Webhosting</a>
            </li>
            <li>
              <a href="#google-analytics-datenschutzerklaerung">Google Analytics Datenschutzerklärung</a>
            </li>
            <li>
              <a href="#google-ads-google-adwords-conversion-tracking-datenschutzerklaerung">Google Ads (Google AdWords) Conversion-Tracking Datenschutzerklärung</a>
            </li>
            <li>
              <a href="#google-adsense-datenschutzerklaerung">Google AdSense Datenschutzerklärung</a>
            </li>
            <li>
              <a href="#bootstrapcdn-datenschutzerklaerung">BootstrapCDN Datenschutzerklärung</a>
            </li>
            <li>
              <a href="#jquery-cdn-datenschutzerklaerung">jQuery CDN Datenschutzerklärung</a>
            </li>
            <li>
              <a href="#social-media">Social Media</a>
            </li>
            <li>
              <a href="#instagram-datenschutzerklaerung">Instagram Datenschutzerklärung</a>
            </li>
          </ul>
          <h2 id="einleitung-ueberblick" className="adsimple-311891325">Einleitung und Überblick</h2>
          <p>Wir haben diese Datenschutzerklärung (Fassung 30.11.2021-311891325) verfasst, um Ihnen gemäß der Vorgaben der <a className="adsimple-311891325" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=311891325" target="_blank" rel="noreferrer">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &#8211; und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &#8211; verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.<br />
            <strong className="adsimple-311891325">Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.</p>
          <p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich beschreiben. Soweit es der Transparenz förderlich ist, werden technische <strong className="adsimple-311891325">Begriffe leserfreundlich erklärt</strong>, <strong className="adsimple-311891325">Links</strong> zu weiterführenden Informationen geboten und <strong className="adsimple-311891325">Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br />
            Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>
          <h2 id="anwendungsbereich" className="adsimple-311891325">Anwendungsbereich</h2>
          <p>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:</p>
          <ul className="adsimple-311891325">
            <li className="adsimple-311891325">alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
            <li className="adsimple-311891325">Social Media Auftritte und E-Mail-Kommunikation</li>
            <li className="adsimple-311891325">mobile Apps für Smartphones und andere Geräte</li>
          </ul>
          <p>
            <strong className="adsimple-311891325">Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert informieren.</p>
          <h2 id="rechtsgrundlagen" className="adsimple-311891325">Rechtsgrundlagen</h2>
          <p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen, personenbezogene Daten zu verarbeiten.<br />
            Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex, dem Zugang zum EU-Recht, unter <a className="adsimple-311891325" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=311891325" target="_blank" rel="noreferrer">https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679</a> nachlesen.</p>
          <p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
          <ol>
            <li className="adsimple-311891325">
              <strong className="adsimple-311891325">Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.</li>
            <li className="adsimple-311891325">
              <strong className="adsimple-311891325">Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.</li>
            <li className="adsimple-311891325">
              <strong className="adsimple-311891325">Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.</li>
            <li className="adsimple-311891325">
              <strong className="adsimple-311891325">Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.</li>
          </ol>
          <p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
          <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
          <ul className="adsimple-311891325">
            <li className="adsimple-311891325">In <strong className="adsimple-311891325">Österreich</strong> ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<strong className="adsimple-311891325">Datenschutzgesetz</strong>), kurz <strong className="adsimple-311891325">DSG</strong>.</li>
            <li className="adsimple-311891325">In <strong className="adsimple-311891325">Deutschland</strong> gilt das <strong className="adsimple-311891325">Bundesdatenschutzgesetz</strong>, kurz<strong className="adsimple-311891325"> BDSG</strong>.</li>
          </ul>
          <p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden Abschnitten darüber.</p>
          <h2 id="kontaktdaten-verantwortliche" className="adsimple-311891325">Kontaktdaten des Verantwortlichen</h2>
          <p>Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br />
            <span className="adsimple-311891325 fontWeight400">Charbel Chougourou<br />
              Lyoner Straße 19, 60528 Frankfurt am Main, Deutschland<br />
              Musterstraße 47, 12312 Musterstadt, Deutschland</span>
            <br />
            <span className="fontWeight400">Vertretungsberechtigt: Charbel Chougourou</span>
            <br />
            E-Mail: <a href="mailto:trinkspielplatz@gmail.com">trinkspielplatz@gmail.com</a>
            <br />
            Telefon: <a href="tel:+49 1735385015">+49 1735385015</a>
            <br />
            Impressum: <a href="http://www.trinkspielplatz.de/impressum/">http://www.trinkspielplatz.de/impressum/</a>
          </p>
          <h2 id="speicherdauer" className="adsimple-311891325">Speicherdauer</h2>
          <p>Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.</p>
          <p>Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.</p>
          <p>Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben.</p>
          <h2 id="rechte-dsgvo" className="adsimple-311891325">Rechte laut Datenschutz-Grundverordnung</h2>
          <p>Laut Artikel 13 DSGVO stehen Ihnen die folgenden Rechte zu, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:</p>
          <ul className="adsimple-311891325">
            <li className="adsimple-311891325">Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:
              <ul className="adsimple-311891325">
                <li className="adsimple-311891325">zu welchem Zweck wir die Verarbeitung durchführen;</li>
                <li className="adsimple-311891325">die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
                <li className="adsimple-311891325">wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, wie die Sicherheit garantiert werden kann;</li>
                <li className="adsimple-311891325">wie lange die Daten gespeichert werden;</li>
                <li className="adsimple-311891325">das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</li>
                <li className="adsimple-311891325">dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen Behörden finden Sie weiter unten);</li>
                <li className="adsimple-311891325">die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
                <li className="adsimple-311891325">ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu gelangen.</li>
              </ul>
            </li>
            <li className="adsimple-311891325">Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.</li>
            <li className="adsimple-311891325">Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.</li>
            <li className="adsimple-311891325">Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.</li>
            <li className="adsimple-311891325">Sie haben laut Artikel 19 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.</li>
            <li className="adsimple-311891325">Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
              <ul className="adsimple-311891325">
                <li className="adsimple-311891325">Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.</li>
                <li className="adsimple-311891325">Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Direktmarketing verwenden.</li>
                <li className="adsimple-311891325">Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Profiling verwenden.</li>
              </ul>
            </li>
            <li className="adsimple-311891325">Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.</li>
          </ul>
          <p>
            <strong className="adsimple-311891325">Kurz gesagt:</strong> Sie haben Rechte &#8211; zögern Sie nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
          <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a className="adsimple-311891325" href="https://www.dsb.gv.at/?tid=311891325" target="_blank" rel="noreferrer">https://www.dsb.gv.at/</a> finden. In Deutschland gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die <a className="adsimple-311891325" href="https://www.bfdi.bund.de" target="_blank" rel="noreferrer">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde zuständig:</p>
          <h2 id="hessen-datenschutzbehoerde" className="adsimple-311891325">Hessen Datenschutzbehörde</h2>
          <p>
            <strong className="adsimple-311891325">Landesbeauftragter für Datenschutz:</strong> Prof. Dr. Alexander Roßnagel<br />
            <strong className="adsimple-311891325">Adresse: </strong>Postfach 31 63, 65021 Wiesbaden<br />
            <strong className="adsimple-311891325">Telefonnr.:</strong> 06 11/140 80<br />
            <strong className="adsimple-311891325">E-Mail-Adresse:</strong> poststelle@datenschutz.hessen.de<br />
            <strong className="adsimple-311891325">Website: </strong>
            <a className="adsimple-311891325" href="https://datenschutz.hessen.de/?tid=311891325" target="_blank" rel="noreferrer">https://datenschutz.hessen.de/</a>
          </p>
          <h2 id="kommunikation" className="adsimple-311891325">Kommunikation</h2>
          <table border="1" cellpadding="15">
            <tbody>
              <tr>
                <td>
                  <strong className="adsimple-311891325">Kommunikation Zusammenfassung</strong>
                  <br />
                  &#x1f465; Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren<br />
                  &#x1f4d3; Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart<br />
                  &#x1f91d; Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.<br />
                  &#x1f4c5; Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften<br />
                  &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
              </tr>
            </tbody>
          </table>
          <p>Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.</p>
          <p>Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während ebensolange gespeichert bzw. so lange es das Gesetz vorschreibt.</p>
          <h3 className="adsimple-311891325">Betroffene Personen</h3>
          <p>Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten Kommunikationswege den Kontakt zu uns suchen.</p>
          <h3 className="adsimple-311891325">Telefon</h3>
          <p>Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
          <h3 className="adsimple-311891325">E-Mail</h3>
          <p>Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät (Computer, Laptop, Smartphone,&#8230;) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
          <h3 className="adsimple-311891325">Online Formulare</h3>
          <p>Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
          <h3 className="adsimple-311891325">Rechtsgrundlagen</h3>
          <p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
          <ul className="adsimple-311891325">
            <li className="adsimple-311891325">Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;</li>
            <li className="adsimple-311891325">Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;</li>
            <li className="adsimple-311891325">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu können.</li>
          </ul>
          <h2 id="cookies" className="adsimple-311891325">Cookies</h2>
          <table border="1" cellpadding="15">
            <tbody>
              <tr>
                <td>
                  <strong className="adsimple-311891325">Cookies Zusammenfassung</strong>
                  <br />
                  &#x1f465; Betroffene: Besucher der Website<br />
                  &#x1f91d; Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br />
                  &#x1f4d3; Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br />
                  &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden bis hin zu Jahren variieren<br />
                  &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
              </tr>
            </tbody>
          </table>
          <h3 className="adsimple-311891325">Was sind Cookies?</h3>
          <p>Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.<br />
            Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerklärung besser verstehen.</p>
          <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Websites speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
          <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Websites verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem &#8220;Hirn&#8221; Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.</p>
          <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.</p>
          <p>Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem Webserver. Dabei fordert der Webbrowser eine Website an und erhält vom Server ein Cookie zurück, welches der Browser erneut verwendet, sobald eine andere Seite angefordert wird.</p>
          <p>
            <img src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg" alt="HTTP Cookie Interaktion zwischen Browser und Webserver" width="100%" />
          </p>
          <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs zugreifen.</p>
          <p>So können zum Beispiel Cookie-Daten aussehen:</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> _ga<br />
            <strong className="adsimple-311891325">Wert:</strong> GA1.2.1326744211.152311891325-9<br />
            <strong className="adsimple-311891325">Verwendungszweck:</strong> Unterscheidung der Websitebesucher<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach 2 Jahren</p>
          <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
          <ul className="adsimple-311891325">
            <li className="adsimple-311891325">Mindestens 4096 Bytes pro Cookie</li>
            <li className="adsimple-311891325">Mindestens 50 Cookies pro Domain</li>
            <li className="adsimple-311891325">Mindestens 3000 Cookies insgesamt</li>
          </ul>
          <h3 className="adsimple-311891325">Welche Arten von Cookies gibt es?</h3>
          <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.</p>
          <p>Man kann 4 Arten von Cookies unterscheiden:</p>
          <p>
            <strong className="adsimple-311891325">Unerlässliche Cookies<br />
            </strong>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.</p>
          <p>
            <strong className="adsimple-311891325">Zweckmäßige Cookies<br />
            </strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern gemessen.</p>
          <p>
            <strong className="adsimple-311891325">Zielorientierte Cookies<br />
            </strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.</p>
          <p>
            <strong className="adsimple-311891325">Werbe-Cookies<br />
            </strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
          <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
          <p>Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir <a className="adsimple-311891325" href="https://tools.ietf.org/html/rfc6265" target="_blank" rel="nofollow noreferrer">https://tools.ietf.org/html/rfc6265</a>, dem Request for Comments der Internet Engineering Task Force (IETF) namens &#8220;HTTP State Management Mechanism&#8221;.</p>
          <h3 className="adsimple-311891325">Zweck der Verarbeitung über Cookies</h3>
          <p>Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, die das Cookie setzt.</p>
          <h3 className="adsimple-311891325">Welche Daten werden verarbeitet?</h3>
          <p>Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten in Cookies gespeichert werden, kann man leider nicht verallgemeinern, aber wir werden Sie im Rahmen der folgenden Datenschutzerklärung über die verarbeiteten bzw. gespeicherten Daten informieren.</p>
          <h3 className="adsimple-311891325">Speicherdauer von Cookies</h3>
          <p>Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert. Manche Cookies werden nach weniger als einer Stunde gelöscht, andere können mehrere Jahre auf einem Computer gespeichert bleiben.</p>
          <p>Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren Browser sämtliche Cookies jederzeit manuell löschen (siehe auch unten &#8220;Widerspruchsrecht&#8221;). Ferner werden Cookies, die auf einer Einwilligung beruhen, spätestens nach Widerruf Ihrer Einwilligung gelöscht, wobei die Rechtmäßigkeit der Speicherung bis dahin unberührt bleibt.</p>
          <h3 className="adsimple-311891325">Widerspruchsrecht &#8211; wie kann ich Cookies löschen?</h3>
          <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur teilweise zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.</p>
          <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
          <p>
            <a className="adsimple-311891325" href="https://support.google.com/chrome/answer/95647?tid=311891325" target="_blank" rel="noreferrer noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311891325" target="_blank" rel="noreferrer noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311891325" target="_blank" rel="noreferrer noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311891325" target="_blank" rel="noreferrer noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311891325" target="_blank" rel="noreferrer noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
          </p>
          <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder &#8220;Cookies deaktivieren Chrome&#8221; im Falle eines Chrome Browsers.</p>
          <h3 className="adsimple-311891325">Rechtsgrundlage</h3>
          <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies eine <strong className="adsimple-311891325">Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes (TKG). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).</p>
          <p>Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt. bestehen <strong className="adsimple-311891325">berechtigte Interessen</strong> (Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten Fällen wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt notwendig.</p>
          <p>Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im Falle Ihrer Einwilligung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.</p>
          <p>In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies informiert, sofern eingesetzte Software Cookies verwendet.</p>
          <h2 id="webhosting" className="adsimple-311891325">Webhosting</h2>
          <table border="1" cellpadding="15">
            <tbody>
              <tr>
                <td>
                  <strong className="adsimple-311891325">Webhosting Zusammenfassung</strong>
                  <br />
                  &#x1f465; Betroffene: Besucher der Website<br />
                  &#x1f91d; Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br />
                  &#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting Provider.<br />
                  &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen<br />
                  &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
              </tr>
            </tbody>
          </table>
          <h3 className="adsimple-311891325">Was ist Webhosting?</h3>
          <p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &#8211; auch personenbezogene Daten &#8211; automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst sparsam und nur mit Begründung verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
          <p>Wenn Sie eine Website auf einem Bildschirm ansehen möchten, verwenden Sie dafür ein Programm, das sich Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari.</p>
          <p>Dieser Webbrowser muss sich zu einem anderen Computer verbinden, wo der Code der Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den Providern, übernommen wird. Diese bieten Webhosting an und sorgen damit für eine verlässliche und fehlerfreie Speicherung der Daten von Websites.</p>
          <p>Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Smartphone) und während der Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen. Einerseits speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen ordentlichen Betrieb zu gewährleisten.</p>
          <p>Zur Veranschaulichung:</p>
          <p>
            <img src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg" alt="Browser und Webserver" width="100%" />
          </p>
          <h3 className="adsimple-311891325">Warum verarbeiten wir personenbezogene Daten?</h3>
          <p>Die Zwecke der Datenverarbeitung sind:</p>
          <ol>
            <li className="adsimple-311891325">Professionelles Hosting der Website und Absicherung des Betriebs</li>
            <li className="adsimple-311891325">zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
            <li className="adsimple-311891325">Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen</li>
          </ol>
          <h3 className="adsimple-311891325">Welche Daten werden verarbeitet?</h3>
          <p>Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie</p>
          <ul className="adsimple-311891325">
            <li className="adsimple-311891325">die komplette Internetadresse (URL) der aufgerufenen Webseite (z. B. https://www.beispielwebsite.de/beispielunterseite.html?tid=311891325)</li>
            <li className="adsimple-311891325">Browser und Browserversion (z. B. Chrome 87)</li>
            <li className="adsimple-311891325">das verwendete Betriebssystem (z. B. Windows 10)</li>
            <li className="adsimple-311891325">die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. https://www.beispielquellsite.de/vondabinichgekommen.html/)</li>
            <li className="adsimple-311891325">den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)</li>
            <li className="adsimple-311891325">Datum und Uhrzeit</li>
            <li className="adsimple-311891325">in Dateien, den sogenannten Webserver-Logfiles</li>
          </ul>
          <h3 className="adsimple-311891325">Wie lange werden Daten gespeichert?</h3>
          <p>In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden eingesehen werden.</p>
          <p>
            <strong className="adsimple-311891325">Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht ohne Zustimmung weiter!</p>
          <h3 className="adsimple-311891325">Rechtsgrundlage</h3>
          <p>Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich präsentieren und Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu können.</p>
          <p>Zwischen uns und dem Hostingprovider besteht in der Regel ein Vertrag über die Auftragsverarbeitung gemäß Art. 28 f. DSGVO, der die Einhaltung von Datenschutz gewährleistet und Datensicherheit garantiert.</p>
          <h2 id="google-analytics-datenschutzerklaerung" className="adsimple-311891325">Google Analytics Datenschutzerklärung</h2>
          <table border="1" cellpadding="15">
            <tbody>
              <tr>
                <td>
                  <strong className="adsimple-311891325">Google Analytics Datenschutzerklärung Zusammenfassung</strong>
                  <br />
                  &#x1f465; Betroffene: Besucher der Website<br />
                  &#x1f91d; Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br />
                  &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
                  &#x1f4c5; Speicherdauer: abhängig von den verwendeten Properties<br />
                  &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
              </tr>
            </tbody>
          </table>
          <h3 className="adsimple-311891325">Was ist Google Analytics?</h3>
          <p>Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics (GA) des amerikanischen Unternehmens Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen auf unserer Website. Wenn Sie beispielsweise einen Link anklicken, wird diese Aktion in einem Cookie gespeichert und an Google Analytics versandt. Mithilfe der Berichte, die wir von Google Analytics erhalten, können wir unsere Website und unser Service besser an Ihre Wünsche anpassen. Im Folgenden gehen wir näher auf das Tracking-Tool ein und informieren Sie vor allem darüber, welche Daten gespeichert werden und wie Sie das verhindern können.</p>
          <p>Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient. Damit Google Analytics funktioniert, wird ein Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere Website besuchen, zeichnet dieser Code verschiedene Handlungen auf, die Sie auf unserer Website ausführen. Sobald Sie unsere Website verlassen, werden diese Daten an die Google-Analytics-Server gesendet und dort gespeichert.</p>
          <p>Google verarbeitet die Daten und wir bekommen Berichte über Ihr Userverhalten. Dabei kann es sich unter anderem um folgende Berichte handeln:</p>
          <ul className="adsimple-311891325">
            <li className="adsimple-311891325">Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User besser kennen und wissen genauer, wer sich für unser Service interessiert.</li>
            <li className="adsimple-311891325">Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung leichter analysieren und verbessern.</li>
            <li className="adsimple-311891325">Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen darüber, wie wir mehr Menschen für unser Service begeistern können.</li>
            <li className="adsimple-311891325">Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website interagieren. Wir können nachvollziehen welchen Weg Sie auf unserer Seite zurücklegen und welche Links Sie anklicken.</li>
            <li className="adsimple-311891325">Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie aufgrund einer Marketing-Botschaft eine gewünschte Handlung ausführen. Zum Beispiel, wenn Sie von einem reinen Websitebesucher zu einem Käufer oder Newsletter-Abonnent werden. Mithilfe dieser Berichte erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.</li>
            <li className="adsimple-311891325">Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website passiert. Zum Beispiel sehen wir wie viele User gerade diesen Text lesen.</li>
          </ul>
          <h3 className="adsimple-311891325">Warum verwenden wir Google Analytics auf unserer Website?</h3>
          <p>Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das bestmögliche Service bieten. Die Statistiken und Daten von Google Analytics helfen uns dieses Ziel zu erreichen.</p>
          <p>Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den Stärken und Schwächen unserer Website. Einerseits können wir unsere Seite so optimieren, dass sie von interessierten Menschen auf Google leichter gefunden wird. Andererseits helfen uns die Daten, Sie als Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an unserer Website verbessern müssen, um Ihnen das bestmögliche Service zu bieten. Die Daten dienen uns auch, unsere Werbe- und Marketing-Maßnahmen individueller und kostengünstiger durchzuführen. Schließlich macht es nur Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die sich dafür interessieren.</p>
          <h3 className="adsimple-311891325">Welche Daten werden von Google Analytics gespeichert?</h3>
          <p>Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige, eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist. So erkennt Sie Google Analytics als neuen User. Wenn Sie das nächste Mal unsere Seite besuchen, werden Sie als „wiederkehrender“ User erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID gespeichert. So ist es überhaupt erst möglich pseudonyme Userprofile auszuwerten.</p>
          <p>Um mit Google Analytics unsere Website analysieren zu können, muss eine Property-ID in den Tracking-Code eingefügt werden. Die Daten werden dann in der entsprechenden Property gespeichert. Für jede neu angelegte Property ist die Google Analytics 4-Property standardmäßig. Alternativ kann man aber auch noch die Universal Analytics Property erstellen. Je nach verwendeter Property werden Daten unterschiedlich lange gespeichert.</p>
          <p>Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre Interaktionen auf unserer Website gemessen. Interaktionen sind alle Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie auch andere Google-Systeme (wie z.B. ein Google-Konto) nützen, können über Google Analytics generierte Daten mit Drittanbieter-Cookies verknüpft werden. Google gibt keine Google Analytics-Daten weiter, außer wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen, wenn es gesetzlich erforderlich ist.</p>
          <p>Folgende Cookies werden von Google Analytics verwendet:</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> _ga<br />
            <strong className="adsimple-311891325">Wert: </strong>2.1326744211.152311891325-5<br />
            <strong className="adsimple-311891325">Verwendungszweck:</strong> Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu speichern. Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach 2 Jahren</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> _gid<br />
            <strong className="adsimple-311891325">Wert: </strong>2.1687193234.152311891325-1<br />
            <strong className="adsimple-311891325">Verwendungszweck:</strong> Das Cookie dient auch zur Unterscheidung der Webseitenbesucher<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach 24 Stunden</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> _gat_gtag_UA_&lt;property-id&gt;<br />
            <strong className="adsimple-311891325">Wert:</strong> 1<br />
            <strong className="adsimple-311891325">Verwendungszweck:</strong> Wird zum Senken der Anforderungsrate verwendet. Wenn Google Analytics über den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_ &lt;property-id&gt;.<br />
            <strong className="adsimple-311891325">Ablaufdatum: </strong>nach 1 Minute</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> AMP_TOKEN<br />
            <strong className="adsimple-311891325">Wert:</strong> keine Angaben<br />
            <strong className="adsimple-311891325">Verwendungszweck:</strong> Das Cookie hat einen Token, mit dem eine User ID vom AMP-Client-ID-Dienst abgerufen werden kann. Andere mögliche Werte weisen auf eine Abmeldung, eine Anfrage oder einen Fehler hin.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach 30 Sekunden bis zu einem Jahr</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> __utma<br />
            <strong className="adsimple-311891325">Wert: </strong>1564498958.1564498958.1564498958.1<br />
            <strong className="adsimple-311891325">Verwendungszweck:</strong> Mit diesem Cookie kann man Ihr Verhalten auf der Website verfolgen und die Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn Informationen an Google Analytics gesendet werden.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach 2 Jahren</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> __utmt<br />
            <strong className="adsimple-311891325">Wert:</strong> 1<br />
            <strong className="adsimple-311891325">Verwendungszweck:</strong> Das Cookie wird wie _gat_gtag_UA_&lt;property-id&gt; zum Drosseln der Anforderungsrate verwendet.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach 10 Minuten</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> __utmb<br />
            <strong className="adsimple-311891325">Wert: </strong>3.10.1564498958<br />
            <strong className="adsimple-311891325">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen zu bestimmen. Es wird jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics gesendet werden.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach 30 Minuten</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> __utmc<br />
            <strong className="adsimple-311891325">Wert:</strong> 167421564<br />
            <strong className="adsimple-311891325">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen für wiederkehrende Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie und wird nur solange gespeichert, bis Sie den Browser wieder schließen.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> Nach Schließung des Browsers</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> __utmz<br />
            <strong className="adsimple-311891325">Wert:</strong> m|utmccn=(referral)|utmcmd=referral|utmcct=/<br />
            <strong className="adsimple-311891325">Verwendungszweck:</strong> Das Cookie wird verwendet, um die Quelle des Besucheraufkommens auf unserer Website zu identifizieren. Das heißt, das Cookie speichert, von wo Sie auf unsere Website gekommen sind. Das kann eine andere Seite bzw. eine Werbeschaltung gewesen sein.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach 6 Monaten</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> __utmv<br />
            <strong className="adsimple-311891325">Wert:</strong> keine Angabe<br />
            <strong className="adsimple-311891325">Verwendungszweck:</strong> Das Cookie wird verwendet, um benutzerdefinierte Userdaten zu speichern. Es wird immer aktualisiert, wenn Informationen an Google Analytics gesendet werden.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach 2 Jahren</p>
          <p>
            <strong className="adsimple-311891325">Anmerkung:</strong> Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google die Wahl ihrer Cookies immer wieder auch verändert.</p>
          <p>Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die mit Google Analytics erhoben werden:</p>
          <p>
            <strong className="adsimple-311891325">Heatmaps:</strong> Google legt sogenannte Heatmaps an. Über Heatmaps sieht man genau jene Bereiche, die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer Seite „unterwegs“ sind.</p>
          <p>
            <strong className="adsimple-311891325">Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer Seite verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung automatisch.</p>
          <p>
            <strong className="adsimple-311891325">Absprungrate</strong> (engl. Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf unserer Website nur eine Seite ansehen und dann unsere Website wieder verlassen.</p>
          <p>
            <strong className="adsimple-311891325">Kontoerstellung:</strong> Wenn Sie auf unserer Website ein Konto erstellen bzw. eine Bestellung machen, erhebt Google Analytics diese Daten.</p>
          <p>
            <strong className="adsimple-311891325">IP-Adresse:</strong> Die IP-Adresse wird nur in gekürzter Form dargestellt, damit keine eindeutige Zuordnung möglich ist.</p>
          <p>
            <strong className="adsimple-311891325">Standort:</strong> Über die IP-Adresse kann das Land und Ihr ungefährer Standort bestimmt werden. Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.</p>
          <p>
            <strong className="adsimple-311891325">Technische Informationen:</strong> Zu den technischen Informationen zählen unter anderem Ihr Browsertyp, Ihr Internetanbieter oder Ihre Bildschirmauflösung.</p>
          <p>
            <strong className="adsimple-311891325">Herkunftsquelle:</strong> Google Analytics beziehungsweise uns interessiert natürlich auch über welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.</p>
          <p>Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z.B., wenn Sie ein Video über unsere Seite abspielen), das Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren Favoriten. Die Aufzählung hat keinen Vollständigkeitsanspruch und dient nur zu einer allgemeinen Orientierung der Datenspeicherung durch Google Analytics.</p>
          <h3 className="adsimple-311891325">Wie lange und wo werden die Daten gespeichert?</h3>
          <p>Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten Server befinden sich in Amerika und folglich werden Ihre Daten meist auf amerikanischen Servern gespeichert. Hier können Sie genau nachlesen wo sich die Google-Rechenzentren befinden: <a className="adsimple-311891325" href="https://www.google.com/about/datacenters/inside/locations/?hl=de" target="_blank" rel="noreferrer noreferrer">https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
          </p>
          <p>Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt es entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise die Hardware bei Google ausfällt oder Naturkatastrophen Server lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google dennoch gering.</p>
          <p>Die Aufbewahrungsdauer der Daten hängt von den verwendeten Properties ab. Bei der Verwendung der neueren Google Analytics 4-Properties ist die Aufbewahrungsdauer Ihrer Userdaten auf 14 Monate fix eingestellt. Für andere sogenannte Ereignisdaten haben wir die Möglichkeit eine Aufbewahrungsdauer von 2 Monaten oder 14 Monaten zu wählen.</p>
          <p>Bei Universal Analytics-Properties ist bei Google Analytics eine Aufbewahrungsdauer Ihrer Userdaten von 26 Monaten standardisiert eingestellt. Dann werden Ihre Userdaten gelöscht. Allerdings haben wir die Möglichkeit, die Aufbewahrungsdauer von Nutzdaten selbst zu wählen. Dafür stehen uns fünf Varianten zur Verfügung:</p>
          <ul className="adsimple-311891325">
            <li className="adsimple-311891325">Löschung nach 14 Monaten</li>
            <li className="adsimple-311891325">Löschung nach 26 Monaten</li>
            <li className="adsimple-311891325">Löschung nach 38 Monaten</li>
            <li className="adsimple-311891325">Löschung nach 50 Monaten</li>
            <li className="adsimple-311891325">Keine automatische Löschung</li>
          </ul>
          <p>Zusätzlich gibt es auch die Option, dass Daten erst dann gelöscht werden, wenn Sie innerhalb des von uns gewählten Zeitraums nicht mehr unsere Website besuchen. In diesem Fall wird die Aufbewahrungsdauer jedes Mal zurückgesetzt, wenn Sie unsere Website innerhalb des festgelegten Zeitraums wieder besuchen.</p>
          <p>Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert. Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer größeren Einheit.</p>
          <h3 className="adsimple-311891325">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
          <p>Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung von Google Analytics-JavaScript (ga.js, analytics.js, dc.js) verhindern Sie, dass Google Analytics Ihre Daten verwendet. Das Browser-Add-on können Sie unter <a className="adsimple-311891325" href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noreferrer noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a> runterladen und installieren. Beachten Sie bitte, dass durch dieses Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.</p>
          <p>Falls Sie grundsätzlich Cookies (unabhängig von Google Analytics) deaktivieren, löschen oder verwalten wollen, gibt es für jeden Browser eine eigene Anleitung:</p>
          <p>
            <a className="adsimple-311891325" href="https://support.google.com/chrome/answer/95647?tid=311891325" target="_blank" rel="noreferrer noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311891325" target="_blank" rel="noreferrer noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311891325" target="_blank" rel="noreferrer noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311891325" target="_blank" rel="noreferrer noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311891325" target="_blank" rel="noreferrer noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
          </p>
          <h3 className="adsimple-311891325">Rechtsgrundlage</h3>
          <p>Der Einsatz von Google Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut<strong className="adsimple-311891325"> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
          <p>Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, dass Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von Google Analytics erkennen wir Fehler der Website, können Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage dafür ist <strong className="adsimple-311891325">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google Analytics gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
          <p>Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
          <p>Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a className="adsimple-311891325" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
          </p>
          <p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche den Standardvertragsklauseln entsprechen und auch für Google Analytics geltend sind, finden Sie unter <a className="adsimple-311891325" href="https://business.safety.google/adsprocessorterms/">https://business.safety.google/adsprocessorterms/</a>.</p>
          <p>Wir hoffen, wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung von Google Analytics näherbringen. Wenn Sie mehr über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden Links: <a className="adsimple-311891325" href="http://www.google.com/analytics/terms/de.html" target="_blank" rel="noreferrer noreferrer">http://www.google.com/analytics/terms/de.html</a> und <a className="adsimple-311891325" href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank" rel="noreferrer noreferrer">https://support.google.com/analytics/answer/6004245?hl=de</a>.</p>
          <h2 id="google-ads-google-adwords-conversion-tracking-datenschutzerklaerung" className="adsimple-311891325">Google Ads (Google AdWords) Conversion-Tracking Datenschutzerklärung</h2>
          <table border="1" cellpadding="15">
            <tbody>
              <tr>
                <td>
                  <strong className="adsimple-311891325">Google Ads (Google AdWords) Conversion-Tracking Datenschutzerklärung Zusammenfassung</strong>
                  <br />
                  &#x1f465; Betroffene: Besucher der Website<br />
                  &#x1f91d; Zweck: wirtschaftlicher Erfolg und die Optimierung unserer Serviceleistung.<br />
                  &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Auch personenbezogene Daten wie Name oder E-Mail-Adresse können verarbeitet werden.<br />
                  &#x1f4c5; Speicherdauer: Conversion-Cookies laufen in der Regel nach 30 Tagen ab und übermitteln keine personenbezogenen Daten<br />
                  &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
              </tr>
            </tbody>
          </table>
          <h3 className="adsimple-311891325">Was ist Google Ads Conversion-Tracking?</h3>
          <p>Wir verwenden als Online-Marketing-Maßnahme Google Ads (früher Google AdWords), um unsere Produkte und Dienstleistungen zu bewerben. So wollen wir im Internet mehr Menschen auf die hohe Qualität unserer Angebote aufmerksam machen. Im Rahmen unserer Werbe-Maßnahmen durch Google Ads verwenden wir auf unserer Website das Conversion-Tracking der Firma Google Inc. In Europa ist allerdings für alle Google-Dienste das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Mithilfe dieses kostenlosen Tracking-Tools können wir unser Werbeangebot an Ihre Interessen und Bedürfnisse besser anpassen. Im Folgenden Artikel wollen wir genauer darauf eingehen, warum wir Conversion-Tracking benutzen, welche Daten dabei gespeichert werden und wie Sie diese Datenspeicherung verhindern.</p>
          <p>Google Ads (früher Google AdWords) ist das hauseigene Online-Werbesystem der Firma Google Inc. Wir sind von der Qualität unseres Angebots überzeugt und wollen, dass so viele Menschen wie möglich unsere Webseite kennenlernen. Im Onlinebereich bietet Google Ads dafür die beste Plattform. Natürlich wollen wir auch einen genauen Überblick über den Kosten-Nutzen-Faktor unserer Werbeaktionen gewinnen. Darum verwenden wir das Conversion-Tracking-Tool von Google Ads.</p>
          <p>Doch was ist eine Conversion eigentlich? Eine Conversion entsteht, wenn Sie von einem rein interessierten Webseitenbesucher zu einem handelnden Besucher werden. Dies passiert immer dann, wenn Sie auf unsere Anzeige klicken und im Anschluss eine andere Aktion ausführen, wie zum Beispiel unsere Webseite besuchen. Mit dem Conversion-Tracking-Tool von Google erfassen wir, was nach einem Klick eines Users auf unsere Google Ads-Anzeige geschieht. Zum Beispiel können wir so sehen, ob Produkte gekauft werden, Dienstleistungen in Anspruch genommen werden oder ob sich User für unseren Newsletter angemeldet haben.</p>
          <h3 className="adsimple-311891325">Warum verwenden wir Google Ads Conversion-Tracking auf unserer Website?</h3>
          <p>Wir setzen Google Ads ein, um auch auf anderen Webseiten auf unser Angebot aufmerksam zu machen. Ziel ist es, dass unsere Werbekampagnen wirklich auch nur jene Menschen erreichen, die sich für unsere Angebote interessieren. Mit dem Conversion-Tracking Tool sehen wir welche Keywords, Anzeigen, Anzeigengruppen und Kampagnen zu den gewünschten Kundenaktionen führen. Wir sehen wie viele Kunden mit unseren Anzeigen auf einem Gerät interagieren und dann eine Conversion durchführen. Durch diese Daten können wir unseren Kosten-Nutzen-Faktor berechnen, den Erfolg einzelner Werbemaßnahmen messen und folglich unsere Online-Marketing-Maßnahmen optimieren. Wir können weiters mithilfe der gewonnenen Daten unsere Webseite für Sie interessanter gestalten und unser Werbeangebot noch individueller an Ihre Bedürfnisse anpassen.</p>
          <h3 className="adsimple-311891325">Welche Daten werden bei Google Ads Conversion-Tracking gespeichert?</h3>
          <p>Wir haben ein Conversion-Tracking-Tag oder Code-Snippet auf unserer Webseite eingebunden, um gewisse User-Aktionen besser analysieren zu können. Wenn Sie nun eine unserer Google Ads-Anzeigen anklicken, wird auf Ihrem Computer (meist im Browser) oder Mobilgerät das Cookie „Conversion“ von einer Google-Domain gespeichert. Cookies sind kleine Textdateien, die Informationen auf Ihrem Computer speichern.</p>
          <p>Hier die Daten der wichtigsten Cookies für das Conversion-Tracking von Google:</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> Conversion<br />
            <strong className="adsimple-311891325">Wert:</strong> EhMI_aySuoyv4gIVled3Ch0llweVGAEgt-mr6aXd7dYlSAGQ311891325-3<br />
            <strong className="adsimple-311891325">Verwendungszweck: </strong>Dieses Cookie speichert jede Conversion, die Sie auf unserer Seite machen, nachdem Sie über eine Google Ad zu uns gekommen sind.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach 3 Monaten</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> _gac<br />
            <strong className="adsimple-311891325">Wert:</strong> 1.1558695989.EAIaIQobChMIiOmEgYO04gIVj5AYCh2CBAPrEAAYASAAEgIYQfD_BwE<br />
            <strong className="adsimple-311891325">Verwendungszweck:</strong> Dies ist ein klassisches Google Analytics-Cookie und dient dem Erfassen verschiedener Handlungen auf unserer Webseite.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach 3 Monaten</p>
          <p>
            <strong className="adsimple-311891325">Anmerkung:</strong> Das Cookie _gac scheint nur in Verbindung mit Google Analytics auf. Die oben angeführte Aufzählung hat keinen Anspruch auf Vollständigkeit, da Google für analytische Auswertung immer wieder auch andere Cookies verwendet.</p>
          <p>Sobald Sie eine Aktion auf unserer Webseite abschließen, erkennt Google das Cookie und speichert Ihre Handlung als sogenannte Conversion. Solange Sie auf unserer Webseite surfen und das Cookie noch nicht abgelaufen ist, erkennen wir und Google, dass Sie über unsere Google-Ads-Anzeige zu uns gefunden haben. Das Cookie wird ausgelesen und mit den Conversion-Daten zurück an Google Ads gesendet. Es ist auch möglich, dass noch andere Cookies zur Messung von Conversions verwendet werden. Das Conversion-Tracking von Google Ads kann mithilfe von Google Analytics noch verfeinert und verbessert werden. Bei Anzeigen, die Google an verschiedenen Orten im Web anzeigt, werden unter unserer Domain möglicherweise Cookies mit dem Namen &#8220;__gads&#8221; oder “_gac” gesetzt. Seit September 2017 werden diverse Kampagneninformationen von analytics.js mit dem _gac-Cookie gespeichert. Das Cookie speichert diese Daten, sobald Sie eine unserer Seiten aufrufen, für die die automatische Tag-Kennzeichnung von Google Ads eingerichtet wurde. Im Gegensatz zu Cookies, die für Google-Domains gesetzt werden, kann Google diese Conversion-Cookies nur lesen, wenn Sie sich auf unserer Webseite befinden. Wir erheben und erhalten keine personenbezogenen Daten. Wir bekommen von Google einen Bericht mit statistischen Auswertungen. So erfahren wir beispielsweise die Gesamtanzahl der User, die unsere Anzeige angeklickt haben und wir sehen, welche Werbemaßnahmen gut ankamen.</p>
          <h3 className="adsimple-311891325">Wie lange und wo werden die Daten gespeichert?</h3>
          <p>An dieser Stelle wollen wir darauf hinweisen, dass wir keinen Einfluss darauf haben, wie Google die erhobenen Daten weiterverwendet. Laut Google werden die Daten verschlüsselt und auf sicheren Servern gespeichert. In den meisten Fällen laufen Conversion-Cookies nach 30 Tagen ab und übermitteln keine personenbezogenen Daten. Die Cookies mit dem Namen „Conversion“ und „_gac“ (das in Verbindung mit Google Analytics zum Einsatz kommt) haben ein Ablaufdatum von 3 Monaten.</p>
          <h3 className="adsimple-311891325">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
          <p>Sie haben die Möglichkeit am Conversion-Tracking von Google Ads nicht teilzunehmen. Wenn Sie das Cookie des Google Conversion-Trackings über Ihren Browser deaktivieren, blockieren Sie das Conversion-Tracking. In diesem Fall werden Sie in der Statistik des Tracking-Tools nicht berücksichtigt. Sie können die Cookie-Einstellungen in Ihrem Browser jederzeit verändern. Bei jedem Browser funktioniert dies etwas anders. Hier finden Sie die Anleitung, wie Sie Cookies in Ihrem Browser verwalten:</p>
          <p>
            <a className="adsimple-311891325" href="https://support.google.com/chrome/answer/95647?tid=311891325" target="_blank" rel="noreferrer noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311891325" target="_blank" rel="noreferrer noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311891325" target="_blank" rel="noreferrer noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311891325" target="_blank" rel="noreferrer noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311891325" target="_blank" rel="noreferrer noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
          </p>
          <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Durch das Herunterladen und Installieren dieses Browser-Plug-ins auf <a className="adsimple-311891325" href="https://support.google.com/ads/answer/7395996" target="_blank" rel="noreferrer noreferrer">https://support.google.com/ads/answer/7395996</a> werden ebenfalls alle „Werbecookies“ deaktiviert. Bedenken Sie, dass Sie durch das Deaktivieren dieser Cookies nicht die Werbeanzeigen verhindern, sondern nur die personalisierte Werbung.</p>
          <h3 className="adsimple-311891325">Rechtsgrundlage</h3>
          <p>Wenn Sie eingewilligt haben, dass das Google Ads Conversion Tracking eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong className="adsimple-311891325"> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch das Google Ads Conversion Tracking vorkommen kann, dar.</p>
          <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, das Google Ads Conversion Tracking zu verwenden, um unser Online-Service und unsere Marketingmaßnahmen zu optimieren. Die dafür entsprechende Rechtsgrundlage ist <strong className="adsimple-311891325">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen das Google Ads Conversion Tracking gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
          <p>Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
          <p>Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a className="adsimple-311891325" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="noreferrer">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
          </p>
          <p>Die Datenverarbeitungsbedingungen für Google Werbeprodukte (Google Ads Controller-Controller Data Protection Terms), welche den Standardvertragsklauseln entsprechen und auch für Google Ads geltend sind, finden Sie unter <a className="adsimple-311891325" href="https://business.safety.google/adscontrollerterms/" target="_blank" rel="follow noreferrer">https://business.safety.google/adscontrollerterms/</a>.</p>
          <p>Wenn Sie Näheres über den Datenschutz bei Google erfahren möchten, empfehlen wir die allgemeine Datenschutzerklärung von Google: <a className="adsimple-311891325" href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noreferrer noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
          <h2 id="google-adsense-datenschutzerklaerung" className="adsimple-311891325">Google AdSense Datenschutzerklärung</h2>
          <table border="1" cellpadding="15">
            <tbody>
              <tr>
                <td>
                  <strong className="adsimple-311891325">Google AdSense Datenschutzerklärung Zusammenfassung</strong>
                  <br />
                  &#x1f465; Betroffene: Besucher der Website<br />
                  &#x1f91d; Zweck: wirtschaftlicher Erfolg und die Optimierung unserer Serviceleistung.<br />
                  &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Auch personenbezogene Daten wie Name oder E-Mail-Adresse können verarbeitet werden.<br />
                  &#x1f4c5; Speicherdauer: abhängig von den verwendeten Cookies und gespeicherten Daten<br />
                  &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
              </tr>
            </tbody>
          </table>
          <h3 className="adsimple-311891325">Was ist Google AdSense?</h3>
          <p>Wir verwenden auf dieser Website Google AdSense. Das ist ein Anzeigenprogramm der Firma Google Inc. In Europa ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit Google AdSense können wir auf dieser Webseite Werbeanzeigen einblenden, die zu unserem Thema passen. So bieten wir Ihnen Anzeigen, die im Idealfall einen richtigen Mehrwert für Sie darstellen. Im Zuge dieses Datenschutztextes über Google AdSense erklären wir Ihnen, warum wir Google AdSense auf unserer Website verwenden, welche Ihrer Daten verarbeitet und gespeichert werden und wie Sie diese Datenspeicherung unterbinden können.</p>
          <p>Das Werbeprogramm Google AdSense gibt es mittlerweile seit 2003. Im Gegensatz zu Google Ads (früher: Google AdWords) kann man hier nicht selbst Werbung schalten. Über Google AdSense werden Werbeanzeigen auf Websites, wie zum Beispiel auf unserer, ausgespielt. Der größte Vorteil dieses Werbedienstes im Vergleich zu manch anderen ist, dass Ihnen Google AdSense nur Anzeigen zeigt, die zu unseren Inhalten passen. Google hat einen eigenen Algorithmus, der berechnet, welche Werbeanzeigen Sie zu Gesicht bekommen. Natürlich wollen wir Ihnen nur Werbung zeigen, die Sie auch interessiert und einen Mehrwert bietet. Google überprüft anhand Ihrer Interessen bzw. Ihres Userverhaltens und anhand unseres Angebots, welche Werbeanzeigen für unsere Website und für unsere User geeignet sind. An dieser Stelle wollen wir auch gleich erwähnen, dass wir für die Auswahl der Werbeanzeigen nicht verantwortlich sind. Wir bieten mit unserer Website lediglich die Werbefläche an. Die Auswahl der angezeigten Werbung trifft Google. Seit August 2013 werden die Anzeigen auch an die jeweilige Benutzeroberfläche angepasst. Das heißt, egal ob Sie von Ihrem Smartphone, Ihrem PC oder Laptop unsere Website besuchen, die Anzeigen passen sich an Ihr Endgerät an.</p>
          <h3 className="adsimple-311891325">Warum verwenden wir Google AdSense auf unserer Website?</h3>
          <p>Das Betreiben einer hochwertigen Website erfordert viel Hingabe und großen Einsatz. Im Grunde sind wir mit der Arbeit an unserer Website nie fertig. Wir versuchen stets unsere Seite zu pflegen und so aktuell wie möglich zu halten. Natürlich wollen wir mit dieser Arbeit auch einen wirtschaftlichen Erfolg erzielen. Darum haben wir uns für Werbeanzeigen als Einnahmequelle entschieden. Das Wichtigste für uns ist allerdings, Ihren Besuch auf unserer Website durch diese Anzeigen nicht zu stören. Mithilfe von Google AdSense wird Ihnen nur Werbung angeboten, die zu unseren Themen und Ihren Interessen passt.</p>
          <p>Ähnlich wie bei der Google-Indexierung für eine Website, untersucht ein Bot den entsprechenden Content und die entsprechenden Angebote auf der Seite. Dann werden die Werbeanzeigen inhaltlich angepasst und präsentiert. Neben den inhaltlichen Überschneidungen zwischen Anzeige und Website-Angebot unterstützt AdSense auch interessensbezogenes Targeting. Das bedeutet, dass Google auch Ihre Daten dazu verwendet, um auf Sie zugeschnittene Werbung anzubieten. So erhalten Sie Werbung, die Ihnen im Idealfall einen echten Mehrwert bietet und wir haben eine höhere Chance ein bisschen etwas zu verdienen.</p>
          <h3 className="adsimple-311891325">Welche Daten werden von Google AdSense gespeichert?</h3>
          <p>Damit Google AdSense eine maßgeschneiderte, auf Sie angepasste Werbung anzeigen kann, werden unter anderem Cookies verwendet. Cookies sind kleine Textdateien, die bestimmte Informationen auf Ihrem Computer speichern.</p>
          <p>In AdSense sollen Cookies bessere Werbung ermöglichen. Die Cookies enthalten keine personenidentifizierbaren Daten. Hierbei ist allerdings zu beachten, dass Google Daten wie zum Beispiel &#8220;Pseudonyme Cookie-IDs&#8221; (Name oder ein anderes Identifikationsmerkmal wird durch ein Pseudonym ersetzt) oder IP-Adressen als nicht personenidentifizierbare Informationen ansieht. Im Rahmen der DSGVO können diese Daten allerdings als personenbezogene Daten gelten. Google AdSense sendet nach jeder Impression (das ist immer dann der Fall, wenn Sie eine Anzeige sehen), jedem Klick und jeder anderen Aktivität, die zu einem Aufruf der Google-AdSense-Server führt, ein Cookie an den Browser. Sofern der Browser das Cookie akzeptiert, wird es dort gespeichert.</p>
          <p>Drittanbieter können im Rahmen von AdSense unter Umständen Cookies in Ihrem Browser platzieren und auslesen bzw. Web-Beacons verwenden, um Daten zu speichern, die sie durch die Anzeigenbereitstellung auf der Website erhalten. Als Web-Beacons bezeichnet man kleine Grafiken, die eine Logdatei-Analyse und eine Aufzeichnung der Logdatei machen. Diese Analyse ermöglicht eine statistische Auswertung für das Online-Marketing.</p>
          <p>Google kann über diese Cookies bestimmte Informationen über Ihr Userverhalten auf unserer Website sammeln. Dazu zählen:</p>
          <ul className="adsimple-311891325">
            <li className="adsimple-311891325">Informationen wie Sie mit einer Anzeige umgehen (Klicks, Impression, Mausbewegungen)</li>
            <li className="adsimple-311891325">Informationen, ob in Ihrem Browser schon eine Anzeige zu einem früheren Zeitpunkt erschienen ist. Diese Daten helfen dabei, Ihnen eine Anzeige nicht öfter anzuzeigen.</li>
          </ul>
          <p>Dabei analysiert Google die Daten zu den angezeigten Werbemitteln und Ihre IP-Adresse und wertet diese aus. Google verwendet die Daten in erster Linie, um die Effektivität einer Anzeige zu messen und das Werbeangebot zu verbessern. Diese Daten werden nicht mit personenbezogenen Daten, die Google möglicherweise über andere Google-Dienste von Ihnen hat, verknüpft.</p>
          <p>Im Folgenden stellen wir Ihnen Cookies vor, die Google AdSense für Trackingzwecke verwendet. Hierbei beziehen wir uns auf eine Test-Website, die ausschließlich Google AdSense installiert hat:<strong className="adsimple-311891325"> </strong>
          </p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> uid<br />
            <strong className="adsimple-311891325">Wert:</strong> 891269189311891325-8<br />
            <strong className="adsimple-311891325">Verwendungszweck:</strong> Das Cookie wird unter der Domain adform.net gespeichert. Es stellt eine eindeutig zugewiesene, maschinell generierte User-ID bereit und sammelt Daten über die Aktivität auf unserer Webseite.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach 2 Monaten</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> C<br />
            <strong className="adsimple-311891325">Wert:</strong> 1<br />
            <strong className="adsimple-311891325">Verwendungszweck:</strong> Dieses Cookie identifiziert, ob Ihr Browser Cookies akzeptiert. Das Cookie wird unter der Domain track.adform.net gespeichert.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach 1 Monat</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> cid<br />
            <strong className="adsimple-311891325">Wert:</strong> 8912691894970695056,0,0,0,0<br />
            <strong className="adsimple-311891325">Verwendungszweck: </strong>Dieses Cookie wird unter der Domain track.adform.net gespeichert, steht für Client-ID und wird verwendet, um die Werbung für Sie zu verbessern. Es kann relevantere Werbung an den Besucher weiterleiten und hilft, die Berichte über die Kampagnenleistung zu verbessern.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach 2 Monaten</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> IDE<br />
            <strong className="adsimple-311891325">Wert:</strong> zOtj4TWxwbFDjaATZ2TzNaQmxrU311891325-1<br />
            <strong className="adsimple-311891325">Verwendungszweck:</strong> Das Cookie wird unter der Domain doubleclick.net gespeichert. Es dient dazu, Ihre Aktionen nach der Anzeige bzw. nach dem Klicken der Anzeige zu registrieren. Dadurch kann man messen, wie gut eine Anzeige bei unseren Besuchern ankommt.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach 1 Monat</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> test_cookie<br />
            <strong className="adsimple-311891325">Wert:</strong> keine Angabe<br />
            <strong className="adsimple-311891325">Verwendungszweck:</strong> Mithilfe des „test_cookies“ kann man überprüfen, ob Ihr Browser überhaupt Cookies unterstützt. Das Cookie wird unter der Domain doubleclick.net gespeichert.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach 1 Monat</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> CT592996<br />
            <strong className="adsimple-311891325">Wert:</strong>733366<br />
            <strong className="adsimple-311891325">Verwendungszweck:</strong> Wird unter der Domain adform.net gespeichert. Das Cookie wird gesetzt sobald Sie auf eine Werbeanzeige klicken. Genauere Informationen über die Verwendung dieses Cookies konnten wir nicht in Erfahrung bringen.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach einer Stunde</p>
          <p>
            <strong className="adsimple-311891325">Anmerkung:</strong> Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google erfahrungsgemäß die Wahl seiner Cookies immer wieder verändert.</p>
          <h3 className="adsimple-311891325">Wie lange und wo werden die Daten gespeichert?</h3>
          <p>Google erfasst Ihre IP-Adresse und verschiedene Aktivitäten, die Sie auf der Website ausführen. Cookies speichern diese Informationen zu den Interaktionen auf unserer Website. Laut Google sammelt und speichert das Unternehmen die angegebenen Informationen auf sichere Weise auf den hauseigenen Google-Servern in den USA.</p>
          <p>Wenn Sie kein Google-Konto haben bzw. nicht angemeldet sind, speichert Google die erhobenen Daten mit einer eindeutigen Kennung (ID) meist auf Ihrem Browser. Die in Cookies gespeicherten eindeutigen IDs dienen beispielsweise dazu, personalisierte Werbung zu gewährleisten. Wenn Sie in einem Google-Konto angemeldet sind, kann Google auch personenbezogene Daten erheben.</p>
          <p>Einige der Daten, die Google speichert, können Sie jederzeit wieder löschen (siehe nächsten Abschnitt). Viele Informationen, die in Cookies gespeichert sind, werden automatisch nach einer bestimmten Zeit wieder gelöscht. Es gibt allerdings auch Daten, die von Google über einen längeren Zeitraum gespeichert werden. Dies ist dann der Fall, wenn Google aus wirtschaftlichen oder rechtlichen Notwendigkeiten, gewisse Daten über einen unbestimmten längeren Zeitraum speichern muss.</p>
          <h3 className="adsimple-311891325">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
          <p>Sie haben immer die Möglichkeit Cookies, die sich auf Ihrem Computer befinden, zu löschen oder zu deaktivieren. Wie genau das funktioniert hängt von Ihrem Browser ab.</p>
          <p>Hier finden Sie die Anleitung, wie Sie Cookies in Ihrem Browser verwalten:</p>
          <p>
            <a className="adsimple-311891325" href="https://support.google.com/chrome/answer/95647?tid=311891325" target="_blank" rel="noreferrer noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311891325" target="_blank" rel="noreferrer noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311891325" target="_blank" rel="noreferrer noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311891325" target="_blank" rel="noreferrer noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311891325" target="_blank" rel="noreferrer noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
          </p>
          <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Durch das Herunterladen und Installieren dieses Browser-Plug-ins auf <a className="adsimple-311891325" href="https://support.google.com/ads/answer/7395996" target="_blank" rel="noreferrer noreferrer">https://support.google.com/ads/answer/7395996</a> werden ebenfalls alle „Werbecookies“ deaktiviert. Bedenken Sie, dass Sie durch das Deaktivieren dieser Cookies nicht die Werbeanzeigen verhindern, sondern nur die personalisierte Werbung.</p>
          <p>Wenn Sie ein Google Konto besitzen, können Sie auf der Website <a className="adsimple-311891325" href="https://adssettings.google.com/authenticated" target="_blank" rel="noreferrer noreferrer">https://adssettings.google.com/authenticated</a> personalisierte Werbung deaktivieren. Auch hier sehen Sie dann weiter Anzeigen, allerdings sind diese nicht mehr an Ihre Interessen angepasst. Dennoch werden die Anzeigen auf der Grundlage von ein paar Faktoren, wie Ihrem Standort, dem Browsertyp und der verwendeten Suchbegriffe, angezeigt.</p>
          <h3 className="adsimple-311891325">Rechtsgrundlage</h3>
          <p>Wenn Sie eingewilligt haben, dass Google AdSense eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong className="adsimple-311891325"> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Google AdSense vorkommen kann, dar.</p>
          <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Google AdSense zu verwenden, um unser Online-Service und unsere Marketingmaßnahmen zu optimieren. Die dafür entsprechende Rechtsgrundlage ist <strong className="adsimple-311891325">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google AdSense gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
          <p>Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
          <p>Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a className="adsimple-311891325" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="noreferrer">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
          </p>
          <p>Die Datenverarbeitungsbedingungen für Google Werbeprodukte (Google Ads Controller-Controller Data Protection Terms), welche den Standardvertragsklauseln entsprechen und auch für Google AdSense geltend sind, finden Sie unter <a className="adsimple-311891325" href="https://business.safety.google/adscontrollerterms/" target="_blank" rel="follow noreferrer">https://business.safety.google/adscontrollerterms/</a>.</p>
          <p>Welche Daten Google grundsätzlich erfasst und wofür sie diese Daten verwenden, können Sie auf <a className="adsimple-311891325" href="https://www.google.com/intl/de/policies/privacy/" target="_blank" rel="noreferrer noreferrer">https://www.google.com/intl/de/policies/privacy/</a> nachlesen.</p>
          <h2 id="bootstrapcdn-datenschutzerklaerung" className="adsimple-311891325">BootstrapCDN Datenschutzerklärung</h2>
          <table border="1" cellpadding="15">
            <tbody>
              <tr>
                <td>
                  <strong className="adsimple-311891325">BootstrapCDN Datenschutzerklärung Zusammenfassung</strong>
                  <br />
                  &#x1f465; Betroffene: Besucher der Website<br />
                  &#x1f91d; Zweck: Optimierung unserer Serviceleistung (um die Website schneller laden zu können)<br />
                  &#x1f4d3; Verarbeitete Daten: Daten wie etwa Ihre IP-Adresse, Browsertyp, Browserversion, welche Webseite geladen wird oder Uhrzeit und Datum des Seitenbesuchs<br />
                  Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
                  &#x1f4c5; Speicherdauer: meisten werden die Daten solange gespeichert, bis sie zur Erfüllung der Dienstleistung nicht mehr benötigt werden<br />
                  &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
              </tr>
            </tbody>
          </table>
          <h3 className="adsimple-311891325">Was ist BootstrapCDN?</h3>
          <p>Um Ihnen all unsere einzelnen Webseiten (Unterseiten unserer Website) auf allen Geräten schnell und sicher ausliefern zu können, nutzen wir das Content Delivery Network (CDN) BootstrapCDN, ein Open-Source-Dienst von <a className="adsimple-311891325" href="https://www.jsdelivr.com/" target="_blank" rel="follow noreferrer noreferrer">jsdelivr.com</a> des polnischen Software-Unternehmens ProspectOne, Królewska 65A/1, 30-081, Kraków, Polen. Ein Content Delivery Network (CDN) ist ein Netzwerk regional verteilter Server, die über das Internet miteinander verbunden sind. Durch dieses Netzwerk können Inhalte, speziell sehr große Dateien, auch bei großen Lastspitzen schnell ausgeliefert werden.</p>
          <h3 className="adsimple-311891325">Warum verwenden wir BootstrapCDN?</h3>
          <p>Selbstverständlich wollen wir Ihnen mit unserer Website einen umfassenden und gut funktionierenden Service bieten. Dazu zählt auch eine schnelle Website. Mit jsdelivr.com-CDN kann unsere Website bei Ihnen viel schneller geladen werden. Besonders hilfreich wird der Einsatz von jsdelivr.com-CDN für User aus dem Ausland, da hier die Seite von einem Server in der Nähe ausgeliefert werden kann.</p>
          <h3 className="adsimple-311891325">Welche Daten werden durch BootstrapCDN verarbeitet?</h3>
          <p>BootstrapCDN funktioniert so, dass sogenannte JavaScript-Bibliotheken an Ihren Browser ausgeliefert werden. Lädt nun Ihr Browser eine Datei vom BootstrapCDN herunter, wird Ihre IP-Adresse während der Verbindung zum Bootstrap-CDN-Server übermittelt. Es können also auch personenbezogene Daten gesendet und gespeichert werden. BootstrapCDN kann somit Userdaten wie IP-Adresse, Browsertyp, Browserversion, welche Webseite geladen wird oder Uhrzeit und Datum des Seitenbesuchs sammeln und speichern. In der Datenschutzerklärung von BootstrapCDN bzw. <a className="adsimple-311891325" href="https://www.jsdelivr.com/" target="_blank" rel="follow noreferrer noreferrer">jsdelivr.com</a> wird ausdrücklich darauf hingewiesen, dass das Unternehmen keine Cookies oder andere Tracking-Services verwendet.</p>
          <h3 className="adsimple-311891325">Wie lange und wo werden die Daten gespeichert?</h3>
          <p>BootstrapCDN hat Server in verschiedenen Ländern verteilt und Ihre Daten können auch außerhalb des Europäischen Wirtschaftsraums gespeichert werden. BootstrapCDN bewahrt personenbezogene Daten, die in unserem Auftrag verarbeitet werden, so lange auf, wie es für die Erbringung angebotener Dienstleistungen erforderlich ist, wie es für die Erfüllung rechtlichen Verpflichtungen, die Beilegung von Streitigkeiten und die Durchsetzung der Vereinbarungen notwendig ist.</p>
          <h3 className="adsimple-311891325">Widerspruchsrecht</h3>
          <p>Sie haben immer das Recht auf Auskunft, Berichtigung und Löschung Ihrer personenbezogenen Daten. Bei Fragen können Sie auch jederzeit Verantwortliche von BootstrapCDN kontaktieren.</p>
          <p>Wenn Sie diese Datenübertragung unterbinden wollen, können Sie einen JavaScript-Blocker (siehe beispielsweise <a className="adsimple-311891325" href="https://noscript.net/" target="_blank" rel="noreferrer noreferrer">https://noscript.net/</a>) installieren oder in Ihrem Browser die Ausführung von JavaScript-Codes deaktivieren. Bitte beachten Sie aber, dass dadurch die Website nicht mehr das gewohnte Service (wie etwa schnelle Ladegeschwindigkeit) bieten kann.</p>
          <h3 className="adsimple-311891325">Rechtsgrundlage</h3>
          <p>Wenn Sie eingewilligt haben, dass BootstrapCDN eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong className="adsimple-311891325"> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch BootstrapCDN vorkommen kann, dar.</p>
          <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, BootstrapCDN zu verwenden, um unser Online-Service zu optimieren und sicherer zu machen. Die dafür entsprechende Rechtsgrundlage ist <strong className="adsimple-311891325">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen BootstrapCDN gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
          <p>Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Die Datenverarbeitung geschieht im Wesentlichen durch BootstrapCDN. Dies kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden. Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne Daten nehmen. Es kann ferner vorkommen, dass diese Daten mit Daten aus möglichen anderen Diensten von BootstrapCDN, bei denen Sie ein Nutzerkonto haben, verknüpft werden.</p>
          <p>Mehr Informationen zum Datenschutz bei BootstrapCDN finden Sie auf <a className="adsimple-311891325" href="https://www.jsdelivr.com/terms/privacy-policy-jsdelivr-net?tid=311891325" target="_blank" rel="follow noreferrer">https://www.jsdelivr.com/terms/privacy-policy-jsdelivr-net.</a>
          </p>
          <h2 id="jquery-cdn-datenschutzerklaerung" className="adsimple-311891325">jQuery CDN Datenschutzerklärung</h2>
          <table border="1" cellpadding="15">
            <tbody>
              <tr>
                <td>
                  <strong className="adsimple-311891325">jQuery CDN Datenschutzerklärung Zusammenfassung</strong>
                  <br />
                  &#x1f465; Betroffene: Besucher der Website<br />
                  &#x1f91d; Zweck: Optimierung unserer Serviceleistung (um die Website schneller laden zu können)<br />
                  &#x1f4d3; Verarbeitete Daten: Daten wie etwa Ihre IP-Adresse<br />
                  Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
                  &#x1f4c5; Speicherdauer: meisten werden die Daten solange gespeichert, bis sie zur Erfüllung der Dienstleistung nicht mehr benötigt werden<br />
                  &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
              </tr>
            </tbody>
          </table>
          <h3 className="adsimple-311891325">Was ist jQuery?</h3>
          <p>Um Ihnen unsere Website bzw. all unsere einzelnen Unterseiten (Webseiten) auf unterschiedlichen Geräten schnell und problemlos auszuliefern, nutzen wir Dienste von jQuery CDN des Unternehmens jQuery Foundation. jQuery wird über das Content Delivery Network (CDN) des amerikanischen Software-Unternehmens StackPath (LCC 2012 McKinney Ave. Suite 1100, Dallas, TX 75201, USA) verteilt. Durch diesen Dienst werden personenbezogene Daten von Ihnen gespeichert, verwaltet und verarbeitet. In dieser Datenschutzerklärung gehen wir näher darauf ein, welche Daten von Ihnen durch die Verwendung von jQuery CDN verarbeitet werden.</p>
          <p>Ein Content Delivery Network (CDN) ist ein Netzwerk regional verteilter Server, die über das Internet miteinander verbunden sind. Durch dieses Netzwerk können Inhalte, speziell sehr große Dateien, auch bei großen Lastspitzen schnell ausgeliefert werden. jQuery legt eine Kopie unserer Website auf ihren Servern an. So kann unsere Website, schnellstmöglich ausgeliefert werden. Das heißt, die Datenübertragung zu Ihrem Browser wird durch ein CDN verkürzt.</p>
          <h3 className="adsimple-311891325">Warum verwenden wir jQuery auf unserer Website?</h3>
          <p>Selbstverständlich wollen wir Ihnen mit unserer Website einen umfassenden und gut funktionierenden Service bieten. Dazu zählt auch eine schnelle Website. Mit jQuery kann unsere Website bei Ihnen viel schneller geladen werden. Besonders hilfreich wird der Einsatz von jQuery für User aus dem Ausland, da hier die Seite von einem Server in der Nähe ausgeliefert werden kann.</p>
          <h3 className="adsimple-311891325">Welche Daten werden durch jQuery verarbeitet?</h3>
          <p>jQuery nutzt JavaScript-Bibliotheken, um unsere Website-Inhalte zügig ausliefern zu können. Dafür lädt ein CDN-Server die nötigen Dateien. Sobald eine Verbindung zum CDN-Server aufgebaut ist, wird Ihre IP-Adresse erfasst und gespeichert. Das geschieht nur, wenn diese Daten nicht schon durch einen vergangenen Websitebesuch in Ihrem Browser gespeichert sind.</p>
          <p>In den Datenschutz-Richtlinien von StackPath wird ausdrücklich erwähnt, dass StackPath aggregierte und anonymisierte Daten von diversen Diensten (wie eben auch jQuery) für die Erweiterung der Sicherheit und für eigene Dienste benutzen. Durch diese Daten können Sie als Person allerdings nicht identifiziert werden.</p>
          <h3 className="adsimple-311891325">Wie lange und wo werden die Daten gespeichert?</h3>
          <p>jQuery bzw. StackPath hat Server in verschiedenen Ländern verteilt und Ihre Daten können somit sowohl in Amerika als auch im Europäischen Wirtschaftsraum gespeichert werden. StackPath bewahrt personenbezogene Daten, die in unserem Auftrag verarbeitet werden, so lange auf, wie es für die Erbringung angebotener Dienstleistungen erforderlich ist, wie es für die Erfüllung rechtlichen Verpflichtungen, die Beilegung von Streitigkeiten und die Durchsetzung der Vereinbarungen notwendig ist.</p>
          <h3 className="adsimple-311891325">Widerspruchsrecht</h3>
          <p>Sie haben immer das Recht auf Auskunft, Berichtigung und Löschung Ihrer personenbezogenen Daten. Bei Fragen können Sie auch jederzeit Verantwortliche von jQuery kontaktieren.</p>
          <p>Wenn Sie nicht wollen, dass es zu einer Datenübertragung kommt, haben Sie immer auch die Möglichkeit Java-Scriptblocker wie beispielsweise <a className="adsimple-311891325" href="https://www.ghostery.com/de/" target="_blank" rel="follow noreferrer noreferrer">ghostery.com</a> oder <a className="adsimple-311891325" href="https://noscript.net/" target="_blank" rel="follow noreferrer noreferrer">noscript.net</a> zu installieren. Sie können aber auch einfach in Ihrem Browser die Ausführung von JavaScript-Codes deaktivieren. Wenn Sie sich für die Deaktivierung von JavaScript-Codes entscheiden, verändern sich auch die gewohnten Funktionen. So wird beispielsweise eine Website nicht mehr so schnell geladen.</p>
          <h3 className="adsimple-311891325">Rechtsgrundlage</h3>
          <p>Wenn Sie eingewilligt haben, dass jQuery CDN eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong className="adsimple-311891325"> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch jQuery CDN vorkommen kann, dar.</p>
          <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, jQuery CDN zu verwenden, um unser Online-Service zu optimieren und sicherer zu machen. Die dafür entsprechende Rechtsgrundlage ist <strong className="adsimple-311891325">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen das Tool gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
          <p>Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Die Datenverarbeitung geschieht im Wesentlichen durch jQuery CDN. Dies kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden. Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne Daten nehmen. Es kann ferner vorkommen, dass diese Daten mit Daten aus möglichen anderen Diensten von jQuery CDN, bei denen Sie ein Nutzerkonto haben, verknüpft werden.</p>
          <p>Mehr Informationen zum Datenschutz bei StackPath finden Sie unter <a className="adsimple-311891325" href="https://www.stackpath.com/legal/privacy-statement" target="_blank" rel="noreferrer noreferrer">https://www.stackpath.com/legal/privacy-statement/</a> und zu jQuery unter <a className="adsimple-311891325" href="https://openjsf.org/wp-content/uploads/sites/84/2019/11/OpenJS-Foundation-Privacy-Policy-2019-11-15.pdf" target="_blank" rel="follow noreferrer noreferrer">https://openjsf.org/wp-content/uploads/sites/84/2019/11/OpenJS-Foundation-Privacy-Policy-2019-11-15.pdf</a>.</p>
          <h2 id="social-media" className="adsimple-311891325">Social Media</h2>
          <table border="1" cellpadding="15">
            <tbody>
              <tr>
                <td>
                  <strong className="adsimple-311891325">Social Media Datenschutzerklärung Zusammenfassung</strong>
                  <br />
                  &#x1f465; Betroffene: Besucher der Website<br />
                  &#x1f91d; Zweck: Darstellung und Optimierung unserer Serviceleistung, Kontakt zu Besuchern, Interessenten u.a., Werbung<br />
                  &#x1f4d3; Verarbeitete Daten: Daten wie etwa Telefonnummern, E-Mail-Adressen, Kontaktdaten, Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse.<br />
                  Mehr Details dazu finden Sie beim jeweils eingesetzten Social-Media-Tool.<br />
                  &#x1f4c5; Speicherdauer: abhängig von den verwendeten Social-Media-Plattformen<br />
                  &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
              </tr>
            </tbody>
          </table>
          <h3 className="adsimple-311891325">Was ist Social Media?</h3>
          <p>Zusätzlich zu unserer Website sind wir auch in diversen Social-Media-Plattformen aktiv. Dabei können Daten von Usern verarbeitet werden, damit wir gezielt User, die sich für uns interessieren, über die sozialen Netzwerke ansprechen können. Darüber hinaus können auch Elemente einer Social-Media-Plattform direkt in unsere Website eingebettet sein. Das ist etwa der Fall, wenn Sie einen sogenannten Social-Button auf unserer Website anklicken und direkt zu unserem Social-Media-Auftritt weitergeleitet werden. Als sogenannte Sozialen Medien oder Social Media werden Websites und Apps bezeichnet, über die angemeldete Mitglieder Inhalte produzieren, Inhalte offen oder in bestimmten Gruppen austauschen und sich mit anderen Mitgliedern vernetzen können.</p>
          <h3 className="adsimple-311891325">Warum nutzen wir Social Media?</h3>
          <p>Seit Jahren sind Social-Media-Plattformen der Ort, wo Menschen online kommunizieren und in Kontakt treten. Mit unseren Social-Media-Auftritten können wir unsere Produkte und Dienstleistungen Interessenten näherbringen. Die auf unserer Website eingebundenen Social-Media-Elemente helfen Ihnen, schnell und ohne Komplikationen zu unseren Social-Media-Inhalten wechseln können.</p>
          <p>Die Daten, die durch Ihre Nutzung eines Social-Media-Kanals gespeichert und verarbeitet werden, haben in erster Linie den Zweck, Webanalysen durchführen zu können. Ziel dieser Analysen ist es, genauere und personenbezogene Marketing- und Werbestrategien entwickeln zu können. Abhängig von Ihrem Verhalten auf einer Social-Media-Plattform, können mit Hilfe der ausgewerteten Daten, passende Rückschlüsse auf Ihre Interessen getroffen werden und sogenannte Userprofile erstellt werden. So ist es den Plattformen auch möglich, Ihnen maßgeschneiderte Werbeanzeigen zu präsentieren. Meistens werden für diesen Zweck Cookies in Ihrem Browser gesetzt, die Daten zu Ihrem Nutzungsverhalten speichern.</p>
          <p>Wir gehen in der Regel davon aus, dass wir datenschutzrechtlich verantwortlich bleiben, auch wenn wir Dienste einer Social-Media-Plattform nutzen. Der Europäische Gerichtshof hat jedoch entschieden, dass in bestimmten Fällen der Betreiber der Social-Media-Plattform zusammen mit uns gemeinsam verantwortlich im Sinne des Art. 26 DSGVO sein kann. Soweit dies der Fall ist, weisen wir gesondert darauf hin und arbeiten auf Grundlage einer diesbezüglichen Vereinbarung. Das Wesentliche der Vereinbarung ist dann weiter unten bei der betroffenen Plattform wiedergegeben.</p>
          <p>Bitte beachten Sie, dass bei der Nutzung der Social-Media-Plattformen oder unserer eingebauten Elemente auch Daten von Ihnen außerhalb der Europäischen Union verarbeitet werden können, da viele Social-Media-Kanäle, beispielsweise Facebook oder Twitter, amerikanische Unternehmen sind. Dadurch können Sie möglicherweise Ihre Rechte in Bezug auf Ihre personenbezogenen Daten nicht mehr so leicht einfordern bzw. durchsetzen.</p>
          <h3 className="adsimple-311891325">Welche Daten werden verarbeitet?</h3>
          <p>Welche Daten genau gespeichert und verarbeitet werden, hängt vom jeweiligen Anbieter der Social-Media-Plattform ab. Aber für gewöhnlich handelt es sich um Daten wie etwa Telefonnummern, E-Mailadressen, Daten, die Sie in ein Kontaktformular eingeben, Nutzerdaten wie zum Beispiel welche Buttons Sie klicken, wen Sie liken oder wem folgen, wann Sie welche Seiten besucht haben, Informationen zu Ihrem Gerät und Ihre IP-Adresse. Die meisten dieser Daten werden in Cookies gespeichert. Speziell wenn Sie selbst ein Profil bei dem besuchten Social-Media-Kanal haben und angemeldet sind, können Daten mit Ihrem Profil verknüpft werden.</p>
          <p>Alle Daten, die über eine Social-Media-Plattform erhoben werden, werden auch auf den Servern der Anbieter gespeichert. Somit haben auch nur die Anbieter Zugang zu den Daten und können Ihnen die passenden Auskünfte geben bzw. Änderungen vornehmen.</p>
          <p>Wenn Sie genau wissen wollen, welche Daten bei den Social-Media-Anbietern gespeichert und verarbeitet werden und wie sie der Datenverarbeitung widersprechen können, sollten Sie die jeweilige Datenschutzerklärung des Unternehmens sorgfältig durchlesen. Auch wenn Sie zur Datenspeicherung und Datenverarbeitung Fragen haben oder entsprechende Rechte geltend machen wollen, empfehlen wir Ihnen, sich direkt an den Anbieter wenden.</p>
          <h3 className="adsimple-311891325">
            <span className="adsimple-311891325" data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Wo und wie lange werden Daten gespeichert?&quot;}" data-sheets-userformat="{&quot;2&quot;:769,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:4,&quot;12&quot;:0}">Dauer der Datenverarbeitung</span>
          </h3>
          <p>Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Beispielsweise speichert die Social-Media-Plattform Facebook Daten, bis sie für den eigenen Zweck nicht mehr benötigt werden. Kundendaten, die mit den eigenen Userdaten abgeglichen werden, werden aber schon innerhalb von zwei Tagen gelöscht. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung, gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch überschritten werden.</p>
          <h3 className="adsimple-311891325">Widerspruchsrecht</h3>
          <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern wie eingebettete Social-Media-Elemente zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
          <p>Da bei Social-Media-Tools Cookies zum Einsatz kommen können, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerklärung über Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
          <h3 className="adsimple-311891325">Rechtsgrundlage</h3>
          <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong className="adsimple-311891325">(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten bei Vorliegen einer Einwilligung auch auf Grundlage unseres berechtigten Interesses <strong className="adsimple-311891325">(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen die Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
          <p>Informationen zu speziellen Social-Media-Plattformen erfahren Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
          <h2 id="instagram-datenschutzerklaerung" className="adsimple-311891325">Instagram Datenschutzerklärung</h2>
          <table border="1" cellpadding="15">
            <tbody>
              <tr>
                <td>
                  <strong className="adsimple-311891325">Instagram Datenschutzerklärung Zusammenfassung</strong>
                  <br />
                  &#x1f465; Betroffene: Besucher der Website<br />
                  &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
                  &#x1f4d3; Verarbeitete Daten: Daten wie etwa Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse.<br />
                  Mehr Details dazu finden Sie weiter unten in der Datenschutzerklärung.<br />
                  &#x1f4c5; Speicherdauer: bis Instagram die Daten für ihre Zwecke nicht mehr benötigt<br />
                  &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
              </tr>
            </tbody>
          </table>
          <h3 className="adsimple-311891325">Was ist Instagram?</h3>
          <p>Wir haben auf unserer Webseite Funktionen von Instagram eingebaut. Instagram ist eine Social Media Plattform des Unternehmens Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA. Instagram ist seit 2012 ein Tochterunternehmen von Facebook Inc. und gehört zu den Facebook-Produkten. Das Einbetten von Instagram-Inhalten auf unserer Webseite nennt man Embedding. Dadurch können wir Ihnen Inhalte wie Buttons, Fotos oder Videos von Instagram direkt auf unserer Webseite zeigen. Wenn Sie Webseiten unserer Webpräsenz aufrufen, die eine Instagram-Funktion integriert haben, werden Daten an Instagram übermittelt, gespeichert und verarbeitet. Instagram verwendet dieselben Systeme und Technologien wie Facebook. Ihre Daten werden somit über alle Facebook-Firmen hinweg verarbeitet.</p>
          <p>Im Folgenden wollen wir Ihnen einen genaueren Einblick geben, warum Instagram Daten sammelt, um welche Daten es sich handelt und wie Sie die Datenverarbeitung weitgehend kontrollieren können. Da Instagram zu Facebook Inc. gehört, beziehen wir unsere Informationen einerseits von den Instagram-Richtlinien, andererseits allerdings auch von den Facebook-Datenrichtlinien selbst.</p>
          <p>Instagram ist eines der bekanntesten Social Media Netzwerken weltweit. Instagram kombiniert die Vorteile eines Blogs mit den Vorteilen von audiovisuellen Plattformen wie YouTube oder Vimeo. Sie können auf „Insta“ (wie viele der User die Plattform salopp nennen) Fotos und kurze Videos hochladen, mit verschiedenen Filtern bearbeiten und auch in anderen sozialen Netzwerken verbreiten. Und wenn Sie selbst nicht aktiv sein wollen, können Sie auch nur anderen interessante Users folgen.</p>
          <h3 className="adsimple-311891325">Warum verwenden wir Instagram auf unserer Website?</h3>
          <p>Instagram ist jene Social Media Plattform, die in den letzten Jahren so richtig durch die Decke ging. Und natürlich haben auch wir auf diesen Boom reagiert. Wir wollen, dass Sie sich auf unserer Webseite so wohl wie möglich fühlen. Darum ist für uns eine abwechslungsreiche Aufbereitung unserer Inhalte selbstverständlich. Durch die eingebetteten Instagram-Funktionen können wir unseren Content mit hilfreichen, lustigen oder spannenden Inhalten aus der Instagram-Welt bereichern. Da Instagram eine Tochtergesellschaft von Facebook ist, können uns die erhobenen Daten auch für personalisierte Werbung auf Facebook dienlich sein. So bekommen unsere Werbeanzeigen nur Menschen, die sich wirklich für unsere Produkte oder Dienstleistungen interessieren.</p>
          <p>Instagram nützt die gesammelten Daten auch zu Messungs- und Analysezwecken. Wir bekommen zusammengefasste Statistiken und so mehr Einblick über Ihre Wünsche und Interessen. Wichtig ist zu erwähnen, dass diese Berichte Sie nicht persönlich identifizieren.</p>
          <h3 className="adsimple-311891325">Welche Daten werden von Instagram gespeichert?</h3>
          <p>Wenn Sie auf eine unserer Seiten stoßen, die Instagram-Funktionen (wie Instagrambilder oder Plug-ins) eingebaut haben, setzt sich Ihr Browser automatisch mit den Servern von Instagram in Verbindung. Dabei werden Daten an Instagram versandt, gespeichert und verarbeitet. Und zwar unabhängig, ob Sie ein Instagram-Konto haben oder nicht. Dazu zählen Informationen über unserer Webseite, über Ihren Computer, über getätigte Käufe, über Werbeanzeigen, die Sie sehen und wie Sie unser Angebot nutzen. Weiters werden auch Datum und Uhrzeit Ihrer Interaktion mit Instagram gespeichert. Wenn Sie ein Instagram-Konto haben bzw. eingeloggt sind, speichert Instagram deutlich mehr Daten über Sie.</p>
          <p>Facebook unterscheidet zwischen Kundendaten und Eventdaten. Wir gehen davon aus, dass dies bei Instagram genau so der Fall ist. Kundendaten sind zum Beispiel Name, Adresse, Telefonnummer und IP-Adresse. Diese Kundendaten werden erst an Instagram übermittelt werden, wenn Sie zuvor „gehasht“ wurden. Hashing meint, ein Datensatz wird in eine Zeichenkette verwandelt. Dadurch kann man die Kontaktdaten verschlüsseln. Zudem werden auch die oben genannten „Event-Daten“ übermittelt. Unter „Event-Daten“ versteht Facebook – und folglich auch Instagram – Daten über Ihr Userverhalten. Es kann auch vorkommen, dass Kontaktdaten mit Event-Daten kombiniert werden. Die erhobenen Kontaktdaten werden mit den Daten, die Instagram bereits von Ihnen hat, abgeglichen.</p>
          <p>Über kleine Text-Dateien (Cookies), die meist in Ihrem Browser gesetzt werden, werden die gesammelten Daten an Facebook übermittelt. Je nach verwendeten Instagram-Funktionen und ob Sie selbst ein Instagram-Konto haben, werden unterschiedlich viele Daten gespeichert.</p>
          <p>Wir gehen davon aus, dass bei Instagram die Datenverarbeitung gleich funktioniert wie bei Facebook. Das bedeutet: wenn Sie ein Instagram-Konto haben oder <a className="adsimple-311891325" href="http://www.instagram.com?tid=311891325" target="_blank" rel="noreferrer noreferrer">www.instagram.com</a> besucht haben, hat Instagram zumindest ein Cookie gesetzt. Wenn das der Fall ist, sendet Ihr Browser über das Cookie Infos an Instagram, sobald Sie mit einer Instagram-Funktion in Berührung kommen. Spätestens nach 90 Tagen (nach Abgleichung) werden diese Daten wieder gelöscht bzw. anonymisiert. Obwohl wir uns intensiv mit der Datenverarbeitung von Instagram beschäftigt haben, können wir nicht ganz genau sagen, welche Daten Instagram exakt sammelt und speichert.</p>
          <p>Im Folgenden zeigen wir Ihnen Cookies, die in Ihrem Browser mindestens gesetzt werden, wenn Sie auf eine Instagram-Funktion (wie z.B. Button oder ein Insta-Bild) klicken. Bei unserem Test gehen wir davon aus, dass Sie kein Instagram-Konto haben. Wenn Sie bei Instagram eingeloggt sind, werden natürlich deutlich mehr Cookies in Ihrem Browser gesetzt.</p>
          <p>Diese Cookies wurden bei unserem Test verwendet:</p>
          <p>
            <strong className="adsimple-311891325">Name: </strong>csrftoken<br />
            <strong className="adsimple-311891325">Wert: </strong>&#8220;&#8221;<br />
            <strong className="adsimple-311891325">Verwendungszweck: </strong>Dieses Cookie wird mit hoher Wahrscheinlichkeit aus Sicherheitsgründen gesetzt, um Fälschungen von Anfragen zu verhindern. Genauer konnten wir das allerdings nicht in Erfahrung bringen.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach einem Jahr</p>
          <p>
            <strong className="adsimple-311891325">Name: </strong>mid<br />
            <strong className="adsimple-311891325">Wert: </strong>&#8220;&#8221;<br />
            <strong className="adsimple-311891325">Verwendungszweck: </strong>Instagram setzt dieses Cookie, um die eigenen Dienstleistungen und Angebote in und außerhalb von Instagram zu optimieren. Das Cookie legt eine eindeutige User-ID fest.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach Ende der Sitzung</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> fbsr_311891325124024<br />
            <strong className="adsimple-311891325">Wert: </strong>keine Angaben<br />
            <strong className="adsimple-311891325">Verwendungszweck: </strong>Dieses Cookie speichert die Log-in-Anfrage für User der Instagram-App.<strong className="adsimple-311891325">
              <br />
            </strong>
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach Ende der Sitzung</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> rur<br />
            <strong className="adsimple-311891325">Wert: </strong>ATN<br />
            <strong className="adsimple-311891325">Verwendungszweck: </strong>Dabei handelt es sich um ein Instagram-Cookie, das die Funktionalität auf Instagram gewährleistet.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach Ende der Sitzung</p>
          <p>
            <strong className="adsimple-311891325">Name:</strong> urlgen<br />
            <strong className="adsimple-311891325">Wert: </strong>&#8220;&#8221;194.96.75.33&#8221;: 1901:1iEtYv:Y833k2_UjKvXgYe311891325&#8221;<br />
            <strong className="adsimple-311891325">Verwendungszweck: </strong>Dieses Cookie dient den Marketingzwecken von Instagram.<br />
            <strong className="adsimple-311891325">Ablaufdatum:</strong> nach Ende der Sitzung</p>
          <p>
            <strong className="adsimple-311891325">Anmerkung:</strong> Wir können hier keinen Vollständigkeitsanspruch erheben. Welche Cookies im individuellen Fall gesetzt werden, hängt von den eingebetteten Funktionen und Ihrer Verwendung von Instagram ab.</p>
          <h3 className="adsimple-311891325">Wie lange und wo werden die Daten gespeichert?</h3>
          <p>Instagram teilt die erhaltenen Informationen zwischen den Facebook-Unternehmen mit externen Partnern und mit Personen, mit denen Sie sich weltweit verbinden. Die Datenverarbeitung erfolgt unter Einhaltung der eigenen Datenrichtlinie. Ihre Daten sind, unter anderem aus Sicherheitsgründen, auf den Facebook-Servern auf der ganzen Welt verteilt. Die meisten dieser Server stehen in den USA.</p>
          <h3 className="adsimple-311891325">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
          <p>Dank der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft, Übertragbarkeit, Berichtigung und Löschung Ihrer Daten. In den Instagram-Einstellungen können Sie Ihre Daten verwalten. Wenn Sie Ihre Daten auf Instagram völlig löschen wollen, müssen Sie Ihr Instagram-Konto dauerhaft löschen.</p>
          <p>Und so funktioniert die Löschung des Instagram-Kontos:</p>
          <p>Öffnen Sie zuerst die Instagram-App. Auf Ihrer Profilseite gehen Sie nach unten und klicken Sie auf „Hilfebereich“. Jetzt kommen Sie auf die Webseite des Unternehmens. Klicken Sie auf der Webseite auf „Verwalten des Kontos“ und dann auf „Dein Konto löschen“.</p>
          <p>Wenn Sie Ihr Konto ganz löschen, löscht Instagram Posts wie beispielsweise Ihre Fotos und Status-Updates. Informationen, die andere Personen über Sie geteilt haben, gehören nicht zu Ihrem Konto und werden folglich nicht gelöscht.</p>
          <p>Wie bereits oben erwähnt, speichert Instagram Ihre Daten in erster Linie über Cookies. Diese Cookies können Sie in Ihrem Browser verwalten, deaktivieren oder löschen. Abhängig von Ihrem Browser funktioniert die Verwaltung immer ein bisschen anders. Hier zeigen wir Ihnen die Anleitungen der wichtigsten Browser.</p>
          <p>
            <a className="adsimple-311891325" href="https://support.google.com/chrome/answer/95647?tid=311891325" target="_blank" rel="noreferrer noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311891325" target="_blank" rel="noreferrer noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311891325" target="_blank" rel="noreferrer noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311891325" target="_blank" rel="noreferrer noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
          </p>
          <p>
            <a className="adsimple-311891325" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311891325" target="_blank" rel="noreferrer noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
          </p>
          <p>Sie können auch grundsätzlich Ihren Browser so einrichten, dass Sie immer informiert werden, wenn ein Cookie gesetzt werden soll. Dann können Sie immer individuell entscheiden, ob Sie das Cookie zulassen wollen oder nicht.</p>
          <h3 className="adsimple-311891325">Rechtsgrundlage</h3>
          <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong className="adsimple-311891325">(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten auch auf Grundlage unseres berechtigten Interesses <strong className="adsimple-311891325">(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen die eingebundene Social-Media-Elemente gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
          <p>Instagram bzw. Facebook verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
          <p>Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Facebook von der EU-Kommission genehmigte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese Klauseln verpflichten Facebook, das EU-Datenschutzniveau bei der Verarbeitung relevanter Daten auch außerhalb der EU einzuhalten. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss sowie die Klauseln u.a. hier: <a className="adsimple-311891325" href="https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de" target="_blank" rel="follow noreferrer">https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de</a>.</p>
          <p>Wir haben versucht, Ihnen die wichtigsten Informationen über die Datenverarbeitung durch Instagram näherzubringen. Auf <a className="adsimple-311891325" href="https://help.instagram.com/519522125107875" target="_blank" rel="noreferrer noreferrer">https://help.instagram.com/519522125107875</a>
            <br />
            können Sie sich noch näher mit den Datenrichtlinien von Instagram auseinandersetzen.</p>
          <p>Alle Texte sind urheberrechtlich geschützt.</p>
          <p className="mt-2">Quelle: Erstellt mit dem <a href="https://www.adsimple.de/datenschutz-generator/" title="Datenschutz Generator von AdSimple für Deutschland">Datenschutz Generator</a> von AdSimple</p>
        </Col>
      </Row>
    </Container>
  )
}
export default Datenschutz