import React, { useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { showModal2, showModal5, showModal6 } from '../slices/modalSlice';
import ReactGA from 'react-ga'
import randomCodeGenerator2 from '../utils/randomCodeGenerator2'
import { changeRoomId, changeId, changeUsername, getRoom, verlassen, setNameBesetzt, getRandomDeckBusfahrer, changePhase, getDeckBusfahrer, setMeineKarten, setBusfahrer, changeUnchecked } from '../slices/busfahrerSlice'
import { SocketContext } from '../context/socket';
import SpielraumModalBusfahrer from './SpielraumModalBusfahrer';
import SpielraumModalBusfahrer2 from './SpielraumModalBusfahrer2';
import { toast } from 'react-toastify';
import Card from './Card';
import { BrowserView, MobileView } from 'react-device-detect'
import BusfahrerCard from './BusfahrerCard';
import { checkGleich, checkHoeher, checkTiefer, getRandomDeck2, resetCorrectInRow, setRandomCard } from '../slices/cardSlice'
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import DragHandle from '@material-ui/icons/DragHandle';
import BusfahrerCardFaceUp from './BusfahrerCardFaceUp';
import SpielraumModalBusfahrer3 from './SpielraumModalBusfahrer3';

const Busfahrer = () => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const { deck, meineKarten, checkArr, trinkanzahlArr, phase, busfahrer, id, username, roomId, roomBusfahrer, werHatVerlassen, verlassenBool } = useSelector(state => state.busfahrer)
  const { nextCard, currentCard, correctInRow, check, number } = useSelector((state) => state.card)
  const cardsForCheck = {
    nextCard,
    currentCard
  }

  useEffect(() => {
    if (username === '' || username.length < 1) {
      dispatch(showModal5(true))
    }
    if (phase === 1 && username === roomBusfahrer.users[0].username) {
      dispatch(getRandomDeckBusfahrer())
    }
    socket.on('deineId', (id) => {
      dispatch(changeId(id))
    })
    socket.on('roomBusfahrer', (roomBusfahrer) => {
      dispatch(getRoom(roomBusfahrer))
    })

    socket.on('karten', (data) => {
      dispatch(getRoom(data.roomBusfahrer))
      dispatch(getDeckBusfahrer(data.deck))
      dispatch(changePhase(data.phase))
    })

    socket.on('meineKarten', (data) => {
      dispatch(changeUnchecked())
      verdeckteKarten()
      dispatch(setMeineKarten(data))
    })

    return () => {
      socket.on('busfahrerBestimmen', (data) => {
        dispatch(setBusfahrer(data.username))
        dispatch(getRoom(data.roomBusfahrer))
        dispatch(showModal6(true))
      })

      socket.on('roomFullBusfahrer', () => {
        toast.error('Raum ist schon voll')
        dispatch(changeRoomId(''))
      })
      socket.on('nameBesetztBusfahrer', () => {
        dispatch(setNameBesetzt(true))
        dispatch(changeRoomId(''))
      })
      socket.on('leftBusfahrer', (data) => {
        dispatch(changePhase(1))
        verlassenMsg(data)
      })
    }
  }, [username, roomBusfahrer, phase, verlassenBool, werHatVerlassen, socket, dispatch]);

  const verdeckteKarten = () => {
    for (let i = 0; i < 15; i++) {
      document.getElementById(i).checked = false
    }
  }

  const verlassenMsg = (data) => {
    toast.error(data.username + ' hat den Raum verlassen')
  }

  const send = () => {
    const roomId = randomCodeGenerator2(5)
    dispatch(changeRoomId(roomId))
    const data = {
      roomId: roomId,
      username: username
    }
    socket.emit('createRoomBusfahrer', data)
  }

  const raumVerlassen = (id) => {
    var users = roomBusfahrer.users.filter(user => user.id !== id)
    var roomData = {
      roomId: roomId,
      deck: [],
      users: users
    }

    var userData = {
      roomId: roomId,
      username: username
    }

    if (users.length === 0) {
      roomData = {
        roomId: roomId,
        users: [
          {
            id: "",
            username: "",
            karten: [],
            checkArr: [true, true, true],
            roomId: "",
          }
        ]
      }
    }

    const data = roomData
    const data2 = userData
    socket.emit('leaveBusfahrer', data)
    socket.emit('leftBusfahrer', data2)
    dispatch(changePhase(1))
    toast.success('Du hast den Raum verlassen')
  }

  const kartenAusteilen = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Karten austeilen'
    })

    const data = {
      roomId: roomId,
      deck: deck
    }
    socket.emit('austeilen', data)
  }

  const busfahren = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Busfahren'
    })

    const data = {
      roomId: roomId
    }
    socket.emit('busfahren', data)
  }

  const zumStart = () => {
    dispatch(changePhase(1))
  }

  return (
    <div>
      <Container className="col col-8 page-content-bg">
        <BrowserView>
          <Row className="pt-3 padding-overall">
            <Col className='col-button'>
              <Button className='btn btn-bg-primary btn-werbinich'
                disabled={username.length === 0 || roomId.length > 0}
                onClick={() => {
                  dispatch(changeUsername(username))
                  send()
                }}>
                Raum erstellen
              </Button>
            </Col>
            <Col className='col-button'>
              <Button className='btn btn-bg-primary btn-werbinich'
                disabled={username.length === 0 || roomId.length > 0}
                onClick={() => dispatch(showModal2())}>
                Raum beitreten
              </Button>
            </Col>
            <Col className='col-button'>
              <Button className='btn btn-bg-schließen btn-werbinich'
                disabled={roomId.length === 0}
                onClick={() => {
                  raumVerlassen(id)
                  dispatch(verlassen())
                }}>
                Raum verlassen
              </Button>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col>
              <div className="spiel-cards-busfahrer">
                <Row>
                  <Col className=''>
                    <p>Raum:</p>
                    <p>{roomId ? roomId : ""}</p>
                  </Col>
                  <Col className='float-left'>
                    <p>Ersteller: </p>
                    <p>{roomBusfahrer.users[0].username !== "" ? roomBusfahrer.users[0].username : ""}</p>
                  </Col>
                  <Col className='float-right'>
                    <p>Spieler:</p>
                    <p>{username}</p>
                  </Col>
                </Row>
                <div className='divider-grey mt-1'></div>
                <div hidden={phase !== 1}>
                  <Row className='mt-3'>
                    <Col>
                      <h5>Busfahrer</h5>
                    </Col>
                    <Col>
                      <p className='float-right'>{roomBusfahrer.users[0].id === "" ? 0 : roomBusfahrer.users.length}/10</p>
                    </Col>
                  </Row>
                  {roomBusfahrer.users.map(user =>
                    <Row key={user.id}>
                      <Col>
                        <p
                          className="fontSizeLarger fontWeightLighter content-after mb-0"
                        >{user.username !== "" ? user.username : ""}
                        </p>
                      </Col>
                    </Row>
                  )}
                </div>
                <div hidden={phase !== 2}>
                  <Row className='mt-3'>
                    <Col>
                      <p className='float-left '>Meine Karten</p>
                      <div className='myCardsDiv'>
                        <div className=''>
                          <BusfahrerCardFaceUp
                            checked={checkArr[0]}
                            trinkanzahl={trinkanzahlArr[0]}
                            id={meineKarten[0]}
                            width={40}
                            rowValue={5} />
                        </div>
                        <div>
                          <BusfahrerCardFaceUp
                            checked={checkArr[1]}
                            trinkanzahl={trinkanzahlArr[1]}
                            id={meineKarten[1]}
                            width={40}
                            rowValue={5} />
                        </div>
                        <div>
                          <BusfahrerCardFaceUp
                            checked={checkArr[2]}
                            trinkanzahl={trinkanzahlArr[2]}
                            id={meineKarten[2]}
                            width={40}
                            rowValue={5} />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <p className='float-right'>{roomBusfahrer.users[0].id === "" ? 0 : roomBusfahrer.users.length}/10</p>
                    </Col>
                  </Row>
                  <Row className='mb-3 center-content'>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={14} width={40} rowValue={5} />
                    </Col>
                  </Row>
                  <Row className='mb-3 center-content'>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={12} width={40} rowValue={4} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={13} width={40} rowValue={4} />
                    </Col>
                  </Row>
                  <Row className='mb-3 center-content'>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={9} width={40} rowValue={3} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={10} width={40} rowValue={3} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={11} width={40} rowValue={3} />
                    </Col>
                  </Row>
                  <Row className='mb-3 center-content'>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={5} width={40} rowValue={2} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={6} width={40} rowValue={2} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={7} width={40} rowValue={2} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={8} width={40} rowValue={2} />
                    </Col>
                  </Row>
                  <Row className='center-content'>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={0} width={40} rowValue={1} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={1} width={40} rowValue={1} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={2} width={40} rowValue={1} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={3} width={40} rowValue={1} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={4} width={40} rowValue={1} />
                    </Col>
                  </Row>
                </div>
                <div hidden={phase !== 3} className='mt-3'>
                  <Row className='mb-3'>
                    <Col>
                      <h5>Busfahrer: {busfahrer !== '' ? busfahrer : ''}</h5>
                    </Col>
                  </Row>
                  <Card width={150} />
                  {number < 51 ?
                    <Row className='mt-4'>
                      <Col>
                        <Button
                          onClick={() => {
                            if (correctInRow === 5) {
                              dispatch(resetCorrectInRow())
                            }
                            dispatch(setRandomCard())
                            dispatch(checkHoeher(cardsForCheck))
                          }}
                          disabled={correctInRow === 5}
                          className="btn btn-bg-primary shadow" size="lg">
                          <ArrowUpward name="svg" />
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          onClick={() => {
                            if (correctInRow === 5) {
                              dispatch(resetCorrectInRow())
                            }
                            dispatch(setRandomCard())
                            dispatch(checkGleich(cardsForCheck))
                          }}
                          disabled={correctInRow === 5}
                          className="btn btn-bg-primary shadow" size="lg">
                          <DragHandle name="svg" />
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          onClick={() => {
                            if (correctInRow === 5) {
                              dispatch(resetCorrectInRow())
                            }
                            dispatch(setRandomCard())
                            dispatch(checkTiefer(cardsForCheck))
                          }}
                          disabled={correctInRow === 5}
                          className="btn btn-bg-primary shadow" size="lg">
                          <ArrowDownward name="svg" />
                        </Button>
                      </Col>
                    </Row>
                    :
                    <Row className='mt-3'>
                      <Col>
                        <Button className="mt-3 btn btn-bg-primary shadow" onClick={() => dispatch(getRandomDeck2())} size="lg">Stapel mischen</Button>
                      </Col>
                    </Row>
                  }
                  <Row className="mt-4">
                    <Col>
                      <p className={check ? 'font-size-30 text-correct' : 'font-size-30 text-false'}>{check === undefined ? "" : check ? "Richtig!" : !check ? "Falsch!" : ""}</p>
                    </Col>
                    <Col>
                      <p className=''>{"Richtig in Folge: \n" + correctInRow}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='pt-3 padding-overall'>
            <Col hidden={(phase !== 1 || roomBusfahrer.users[0].username !== username) || roomBusfahrer.users[0].username === ''}>
              <Button
                disabled={roomBusfahrer.users.length < 2}
                className="btn btn-bg-primary width-200"
                onClick={() => kartenAusteilen()}
                size="lg"
              >Karten austeilen</Button>
            </Col>
            <Col hidden={(phase !== 2 || roomBusfahrer.users[0].username !== username) || roomBusfahrer.users[0].username === ''}>
              <Button
                disabled={roomBusfahrer.users.length < 2}
                className="btn btn-bg-primary width-200"
                onClick={() => busfahren()}
                size="lg"
              >Busfahren</Button>
            </Col>
            <Col hidden={((correctInRow !== 5 || phase !== 3) || roomBusfahrer.users[0].username !== username) || roomBusfahrer.users[0].username === ''}>
              <Button
                className="btn btn-bg-primary width-200"
                onClick={() => zumStart()}
                size="lg"
              >Zum Start</Button>
            </Col>
          </Row>
        </BrowserView>

        <MobileView>
          <Row className="pt-3 padding-overall">
            <Col className='col-button'>
              <Button className='btn btn-bg-primary btn-werbinich'
                disabled={username.length === 0 || roomId.length > 0}
                onTouchStart={() => {
                  dispatch(changeUsername(username))
                  send()
                }}>
                Raum erstellen
              </Button>
            </Col>
            <Col className='col-button'>
              <Button className='btn btn-bg-primary btn-werbinich'
                disabled={username.length === 0 || roomId.length > 0}
                onTouchStart={() => dispatch(showModal2())}>
                Raum beitreten
              </Button>
            </Col>
            <Col className='col-button'>
              <Button className='btn btn-bg-schließen btn-werbinich'
                disabled={roomId.length === 0}
                onTouchStart={() => {
                  raumVerlassen(id)
                  dispatch(verlassen())
                }}>
                Raum verlassen
              </Button>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col>
              <div className="spiel-cards-busfahrer">
                <Row>
                  <Col className=''>
                    <p>Raum:</p>
                    <p>{roomId ? roomId : ""}</p>
                  </Col>
                  <Col className='float-left'>
                    <p>Ersteller: </p>
                    <p>{roomBusfahrer.users[0].username !== "" ? roomBusfahrer.users[0].username : ""}</p>
                  </Col>
                  <Col className='float-right'>
                    <p>Spieler:</p>
                    <p>{username}</p>
                  </Col>
                </Row>
                <div className='divider-grey mt-1'></div>
                <div hidden={phase !== 1}>
                  <Row className='mt-3'>
                    <Col>
                      <h5>Busfahrer</h5>
                    </Col>
                    <Col>
                      <p className='float-right'>{roomBusfahrer.users[0].id === "" ? 0 : roomBusfahrer.users.length}/10</p>
                    </Col>
                  </Row>
                  {roomBusfahrer.users.map(user =>
                    <Row key={user.id}>
                      <Col>
                        <p
                          className="fontSizeLarger fontWeightLighter content-after mb-0"
                        >{user.username !== "" ? user.username : ""}
                        </p>
                      </Col>
                    </Row>
                  )}
                </div>
                <div hidden={phase !== 2}>
                  <Row className='mt-3'>
                    <Col>
                      <p className='float-left '>Meine Karten</p>
                      <div className='myCardsDiv'>
                        <div>
                          <BusfahrerCardFaceUp
                            checked={checkArr[0]}
                            trinkanzahl={trinkanzahlArr[0]}
                            id={meineKarten[0]}
                            width={40}
                            rowValue={5} />
                        </div>
                        <div>
                          <BusfahrerCardFaceUp
                            checked={checkArr[1]}
                            trinkanzahl={trinkanzahlArr[1]}
                            id={meineKarten[1]}
                            width={40}
                            rowValue={5} />
                        </div>
                        <div>
                          <BusfahrerCardFaceUp
                            checked={checkArr[2]}
                            trinkanzahl={trinkanzahlArr[2]}
                            id={meineKarten[2]}
                            width={40}
                            rowValue={5} />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <p className='float-right'>{roomBusfahrer.users[0].id === "" ? 0 : roomBusfahrer.users.length}/10</p>
                    </Col>
                  </Row>
                  <Row className='mb-3 center-content'>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={14} width={40} rowValue={5} />
                    </Col>
                  </Row>
                  <Row className='mb-3 center-content'>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={12} width={40} rowValue={4} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={13} width={40} rowValue={4} />
                    </Col>
                  </Row>
                  <Row className='mb-3 center-content'>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={9} width={40} rowValue={3} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={10} width={40} rowValue={3} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={11} width={40} rowValue={3} />
                    </Col>
                  </Row>
                  <Row className='mb-3 center-content'>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={5} width={40} rowValue={2} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={6} width={40} rowValue={2} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={7} width={40} rowValue={2} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={8} width={40} rowValue={2} />
                    </Col>
                  </Row>
                  <Row className='center-content'>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={0} width={40} rowValue={1} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={1} width={40} rowValue={1} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={2} width={40} rowValue={1} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={3} width={40} rowValue={1} />
                    </Col>
                    <Col className='busfahrer-cards'>
                      <BusfahrerCard id={4} width={40} rowValue={1} />
                    </Col>
                  </Row>
                </div>
                <div hidden={phase !== 3} className='mt-3'>
                  <Row className='mb-3'>
                    <Col>
                      <h5>Busfahrer: {busfahrer !== '' ? busfahrer : ''}</h5>
                    </Col>
                  </Row>
                  <Card width={150} />
                  {number < 51 ?
                    <Row className='mt-4'>
                      <Col>
                        <Button
                          onTouchStart={() => {
                            if (correctInRow === 5) {
                              dispatch(resetCorrectInRow())
                            }
                            dispatch(setRandomCard())
                            dispatch(checkHoeher(cardsForCheck))
                          }}
                          disabled={correctInRow === 5}
                          className="btn btn-bg-primary shadow" size="lg">
                          <ArrowUpward name="svg" />
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          onTouchStart={() => {
                            if (correctInRow === 5) {
                              dispatch(resetCorrectInRow())
                            }
                            dispatch(setRandomCard())
                            dispatch(checkGleich(cardsForCheck))
                          }}
                          disabled={correctInRow === 5}
                          className="btn btn-bg-primary shadow" size="lg">
                          <DragHandle name="svg" />
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          onTouchStart={() => {
                            if (correctInRow === 5) {
                              dispatch(resetCorrectInRow())
                            }
                            dispatch(setRandomCard())
                            dispatch(checkTiefer(cardsForCheck))
                          }}
                          disabled={correctInRow === 5}
                          className="btn btn-bg-primary shadow" size="lg">
                          <ArrowDownward name="svg" />
                        </Button>
                      </Col>
                    </Row>
                    :
                    <Row className='mt-3'>
                      <Col>
                        <Button className="mt-3 btn btn-bg-primary shadow" onTouchStart={() => dispatch(getRandomDeck2())} size="lg">Stapel mischen</Button>
                      </Col>
                    </Row>
                  }
                  <Row className="mt-4">
                    <Col>
                      <p className={check ? 'font-size-30 text-correct' : 'font-size-30 text-false'}>{check === undefined ? "" : check ? "Richtig!" : !check ? "Falsch!" : ""}</p>
                    </Col>
                    <Col>
                      <p className=''>{"Richtig in Folge: \n" + correctInRow}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='pt-3 padding-overall'>
            <Col hidden={(phase !== 1 || roomBusfahrer.users[0].username !== username) || roomBusfahrer.users[0].username === ''}>
              <Button
                disabled={roomBusfahrer.users.length < 2}
                className="btn btn-bg-primary width-200"
                onTouchStart={() => kartenAusteilen()}
                size="lg"
              >Karten austeilen</Button>
            </Col>
            <Col hidden={(phase !== 2 || roomBusfahrer.users[0].username !== username) || roomBusfahrer.users[0].username === ''}>
              <Button
                disabled={roomBusfahrer.users.length < 2}
                className="btn btn-bg-primary width-200"
                onTouchStart={() => busfahren()}
                size="lg"
              >Busfahren</Button>
            </Col>
            <Col hidden={((correctInRow !== 5 || phase !== 3) || roomBusfahrer.users[0].username !== username) || roomBusfahrer.users[0].username === ''}>
              <Button
                className="btn btn-bg-primary width-200"
                onTouchStart={() => zumStart()}
                size="lg"
              >Zum Start</Button>
            </Col>
          </Row>
        </MobileView>
      </Container>
      <SpielraumModalBusfahrer />
      <SpielraumModalBusfahrer2 />
      <SpielraumModalBusfahrer3 />
    </div >
  )
}
export default Busfahrer