import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: "",
  room: {
    roomId: "",
    users: [
      {
        id: "",
        username: "",
        werbinich: "",
      }
    ]
  },
  username: "",
  roomId: "",
  raumGefunden: true,
  nameBesetzt: false,
  namen: "",
  speichernFuer: "",
  // speichernFuerArr: [],
}


export const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    changeId(state, action) {
      state.id = action.payload
    },
    getRoom(state, action) {
      state.room = action.payload
    },
    changeUsername(state, action) {
      state.username = action.payload
    },
    changeRoomId(state, action) {
      state.roomId = action.payload
    },
    setRaumGefunden(state, action) {
      state.raumGefunden = action.payload
    },
    setNameBesetzt(state, action) {
      state.nameBesetzt = action.payload
    },
    changeNamen(state, action) {
      state.namen = action.payload
    },
    speicherNamenFuer(state, action) {
      state.speichernFuer = state.room.users[action.payload].username
    },
    // setSpeicherNamenFuerArr(state, action) {
    //   state.speichernFuerArr = action.payload
    // },
    verlassen(state, action) {
      state.roomId = initialState.roomId
      state.room.users = initialState.room.users
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase((state) => {

      })
  }
})

export const {
  changeId,
  getRoom,
  getUsers,
  changeUsername,
  changeRoomId,
  setRaumGefunden,
  setNameBesetzt,
  changeNamen,
  speicherNamenFuer,
  // setSpeicherNamenFuerArr,
  verlassen
} = socketSlice.actions

export default socketSlice.reducer