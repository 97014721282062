import React from 'react'
import { Modal, Button, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { showModal1 } from '../slices/modalSlice';
import { BrowserView, MobileView } from 'react-device-detect'
import image from '../images/stickman.svg'

const SiegerModal = (props) => {
  const dispatch = useDispatch();
  const { modal1, modalText } = useSelector(state => state.modal)

  return (
    <div>
      <Modal
        show={modal1}
        onHide={() => {
          dispatch(showModal1())
          props.nochmal()
        }}
        centered
        className={modalText === 1 ? 'rotieren' : ''}
      >
        <BrowserView>
          <Modal.Header>
            <Modal.Title>
              Spieler {modalText} hat gewonnen!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='fontSizeXXLarge'>Spieler {modalText === 1 ? 2 : 1} trinkt {Math.floor((Math.random() * 7)) + 1} Schluck/e!</p>
            <Image className={modalText === 2 ? 'flipped-img modal-img' : 'modal-img'} src={image} />
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-bg-primary"
              onClick={() => {
                dispatch(showModal1())
                props.nochmal()
              }}>Nochmal</Button>
          </Modal.Footer>
        </BrowserView>
        
        <MobileView>
          <Modal.Header>
            <Modal.Title>
              Spieler {modalText} hat gewonnen!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='fontSizeXXLarge'>Spieler {modalText === 1 ? 2 : 1} trinkt {Math.floor((Math.random() * 7)) + 1} Schluck/e!</p>
            <Image className={modalText === 2 ? 'flipped-img modal-img' : 'modal-img'} src={image} />
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-bg-primary"
              onTouchStart={() => {
                dispatch(showModal1())
                props.nochmal()
              }}>Nochmal</Button>
          </Modal.Footer>
        </MobileView>
      </Modal>
    </div>
  )
}
export default SiegerModal