import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Col, Container, Row, Image } from 'react-bootstrap'
import { showModal1, showModal2 } from '../slices/modalSlice';
import SiegerModal from './SiegerModal';
import ZuSchnellModal from './ZuSchnellModal';
import { BrowserView, MobileView } from 'react-device-detect'
import image from '../images/singlestickman.svg'



const Bang = () => {
  const dispatch = useDispatch();
  var time, timeout
  var player1, player2
  var stickman = document.getElementsByName('stickman')
  var bangButton = document.getElementsByName('bangButton')
  var display = document.getElementsByName('time')
  var timePlayer1 = 0
  var timePlayer2 = 0

  const countdown = (seconds) => {
    stickman[0].setAttribute('hidden', 'true')
    stickman[1].setAttribute('hidden', 'true')
    bangButton[0].textContent = 'BANG!'
    bangButton[1].textContent = 'BANG!'
    bangButton[0].removeAttribute('disabled')
    bangButton[1].removeAttribute('disabled')
    player1 = undefined
    player2 = undefined
    
    time = setInterval(function () {
      seconds = seconds - 1
      display[0].textContent = seconds
      display[1].textContent = seconds
      if (seconds === 1) {
        clearInterval(time)
        var bangTime = Math.floor((Math.random() * 5000))
        timeout = setTimeout(setBang, bangTime)
      }
    }, 1019)
  }

  const setBang = () => {
    display[0].textContent = 'BANG!'
    display[1].textContent = 'BANG!'
    player1 = 1
    player2 = 2
  }

  const setTimePlayer1 = (seconds) => {
    if (bangButton[0].textContent === 'Start') {
      countdown(seconds)
      return
    }
    if (player1 !== undefined) {
      timePlayer1 = Date.now()
      if (timePlayer1 > 0 && timePlayer2 > 0 && timePlayer1 < timePlayer2) {
        dispatch(showModal1(1))
      }
      else if (timePlayer1 > 0 && timePlayer2 === 0) {
        dispatch(showModal1(1))
      }
    }
    else {
      clearInterval(time)
      clearTimeout(timeout)
      dispatch(showModal2(1))
      display[0].textContent = ''
      display[1].textContent = ''
    }
  }

  const setTimePlayer2 = (seconds) => {
    if (bangButton[1].textContent === 'Start') {
      countdown(seconds)
      return
    }
    if (player2 !== undefined) {
      timePlayer2 = Date.now()
      if (timePlayer2 > 0 && timePlayer1 > 0 && timePlayer2 < timePlayer1) {
        dispatch(showModal1(2))
      }
      else if (timePlayer2 > 0 && timePlayer1 === 0) {
        dispatch(showModal1(2))
      }
    }
    else {
      clearInterval(time)
      clearTimeout(timeout)
      dispatch(showModal2(2))
      display[0].textContent = ''
      display[1].textContent = ''
    }
  }

  const nochmal = () => {
    stickman[0].removeAttribute('hidden')
    stickman[1].removeAttribute('hidden')
    // bangButton[0].removeAttribute('disabled')
    // bangButton[1].removeAttribute('disabled')
    bangButton[0].textContent = 'Start'
    bangButton[1].textContent = 'Start'
    display[0].textContent = ''
    display[1].textContent = ''
    timePlayer1 = 0
    timePlayer2 = 0
  }

  useEffect(() => {

  }, [])

  return (
    <div>
      <Container className="col col-8 page-content-bg">
        <BrowserView>
        <Row className="pt-3 padding-overall">
          <Col>
            <div className="spiel-cards-hoeher-tiefer flex-container">
              <Row className='rotieren'>
                <Col>
                  <p name='time' className='count-txt'></p>
                  <Image name='stickman' className='stickman-img mb-1' src={image} />
                  <p>Spieler 1</p>
                  <Button name='bangButton' className='btn btn-bg-schließen width-200' size='lg' onClick={() => setTimePlayer1(4)}>
                    Start
                  </Button>
                </Col>
              </Row>
              <div className='universal-divider'></div>
              <Row>
                <Col>
                  <p name='time' className='count-txt'></p>
                  <Image name='stickman' className='stickman-img mb-1' src={image} />
                  <p>Spieler 2</p>
                  <Button name='bangButton' className='btn btn-bg-schließen width-200' size='lg' onClick={() => setTimePlayer2(4)}>
                    Start
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        </BrowserView>
        
        <MobileView>
        <Row className="pt-3 padding-overall">
          <Col>
            <div className="spiel-cards-hoeher-tiefer flex-container">
              <Row className='rotieren'>
                <Col>
                  <p name='time' className='count-txt'></p>
                  <Image name='stickman' className='stickman-img mb-1' src={image} />
                  <p>Spieler 1</p>
                  <Button name='bangButton' className='btn btn-bg-schließen width-200' size='lg' onTouchStart={() => setTimePlayer1(4)}>
                    Start
                  </Button>
                </Col>
              </Row>
              <div className='universal-divider'></div>
              <Row>
                <Col>
                  <p name='time' className='count-txt'></p>
                  <Image name='stickman' className='stickman-img mb-1' src={image} />
                  <p>Spieler 2</p>
                  <Button name='bangButton' className='btn btn-bg-schließen width-200' size='lg' onTouchStart={() => setTimePlayer2(4)}>
                    Start
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        </MobileView>
      </Container>
      <SiegerModal nochmal={nochmal}/>
      <ZuSchnellModal nochmal={nochmal}/>
    </div>
  )
}
export default Bang