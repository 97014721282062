import React, { useEffect } from 'react'
import Header from './components/Header'
import Homepage from './components/Homepage'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Nochnie from './components/Nochnie';
import Eher from './components/Eher';
import AnleitungsModal from './components/AnleitungsModal';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import Kontakt from './components/Kontakt';
import Datenschutz from './components/Datenschutz';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/Footer';
import ReactGa from 'react-ga';
import WahrheitPflicht from './components/WahrheitPflicht';
import Busfahrer from './components/Busfahrer';
import WerBinIch from './components/WerBinIch';
import HoeherTiefer from './components/HoeherTiefer';
import Spiele from './components/Spiele';
import FragenAdmin from './components/FragenAdmin';
import { SocketContext, socket } from './context/socket';
import Bang from './components/Bang';



function App() {
  useEffect(() => {
    ReactGa.initialize('UA-214515280-1')

    //to report page view
    ReactGa.pageview(window.location.pathname + window.location.search)
  }, [])
  return (
    <>
      <SocketContext.Provider value={socket}>
        <Header />
        <Router>
          <Routes>
            <Route exavt path="/" element={<Homepage />} />
            <Route exact path="spiele/" element={<><AnleitungsModal /><Spiele /></>} />
            <Route exact path="nochnie/" element={<Nochnie />} />
            <Route exact path="eher/" element={<Eher />} />
            <Route exact path="wahrheitpflicht/" element={<WahrheitPflicht />} />
            <Route exact path="busfahrer/" element={<div><Busfahrer /></div>} />
            <Route exact path="werbinich/" element={<div><WerBinIch /></div>} />
            <Route exact path="hoehertiefer/" element={<div><HoeherTiefer /></div>} />
            <Route exact path="bang/" element={<div><Bang /></div>} />
            <Route exact path="kontakt/" element={<Kontakt />} />
            <Route exact path="datenschutz/" element={<Datenschutz />} />
            <Route exact path="fragenadmin/" element={<FragenAdmin />} />
          </Routes>
        </Router>
        <Footer />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover />
      </SocketContext.Provider>
    </>
  )
}
export default App