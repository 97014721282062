import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

const Kontakt = () => {
  return (
    <Container className="col col-8 page-content">
      <Row className="mt-4">
        <h2>Kontakt</h2>
        <Col>
          <div className="mt-4 kontakt-cards">
            <p>Für Fragen, Vorschläge und Ideen, schreib mir gerne eine Mail</p>
            <a href="mailto:trinkspielplatz@gmail.com">trinkspielplatz@gmail.de</a>
          </div>
        </Col>
      </Row>
    </Container >
  )
}
export default Kontakt