import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { host } from '../constants';

const initialState = {
  status: "idle",
  status2: "idle",
  randomNochnie: [],
  randomEher: [],
  allNochnie: [],
  allEher: [],
  allWahrheit: [],
  allPflicht: [],
  nochnie: {},
  eher: {},
  wahrheit: {},
  pflicht: {},
  werbinich: {},
  visibleWahrheit: false,
  visibleWahrheit2: true,
  visiblePflicht: false,
  visiblePflicht2: true,
  visibleTrinkanzahl: true,
  disable: true,
  trinkanzahl: undefined,
  amount: 0,
  amount2: 0,
  suchFilter: "",
  spiel: ""
};

const randomNumbers = [3, 4, 5, 6, 7]

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}


export const fetchRandomNochnie = createAsyncThunk('fragen/fetchRandomNochnie', async (nothing, thunkAPI) => {
  let message =
    await axios.get(`${host}/trinkspiele/nochnie/`)
      .then((response) => thunkAPI.fulfillWithValue(response.data))
      .catch((error) => thunkAPI.rejectWithValue(error.response.data))
  if (Array.isArray(message.payload)) {
    shuffleArray(message.payload)
  }
  return message;
})

export const fetchAllNochnie = createAsyncThunk('fragen/fetchAllNochnie', async (nothing, thunkAPI) => {
  let message =
    await axios.get(`${host}/trinkspiele/nochnie/`)
      .then((response) => thunkAPI.fulfillWithValue(response.data))
      .catch((error) => thunkAPI.rejectWithValue(error.response.data))
  return message;
})

export const fetchRandomEher = createAsyncThunk('fragen/fetchRandomEher', async (nothing, thunkAPI) => {
  let message =
    await axios.get(`${host}/trinkspiele/eher/`)
      .then((response) => thunkAPI.fulfillWithValue(response.data))
      .catch((error) => thunkAPI.rejectWithValue(error.response.data))
  if (Array.isArray(message.payload)) {
    shuffleArray(message.payload)
  }
  return message;
})

export const fetchAllEher = createAsyncThunk('fragen/fetchAllEher', async (nothing, thunkAPI) => {
  let message =
    await axios.get(`${host}/trinkspiele/eher/`)
      .then((response) => thunkAPI.fulfillWithValue(response.data))
      .catch((error) => thunkAPI.rejectWithValue(error.response.data))
  return message;
})

export const fetchAllWahrheit = createAsyncThunk('fragen/fetchallWahrheit', async (nothing, thunkAPI) => {
  let message =
    await axios.get(`${host}/trinkspiele/wahrheit/`)
      .then((response) => thunkAPI.fulfillWithValue(response.data))
      .catch((error) => thunkAPI.rejectWithValue(error.response.data))
  return message;
})

export const fetchAllPflicht = createAsyncThunk('fragen/fetchallPflicht', async (nothing, thunkAPI) => {
  let message =
    await axios.get(`${host}/trinkspiele/pflicht/`)
      .then((response) => thunkAPI.fulfillWithValue(response.data))
      .catch((error) => thunkAPI.rejectWithValue(error.response.data))
  return message;
})

export const writeToFile = createAsyncThunk('fragen/writeToFile', async (nothing, thunkAPI) => {
  const state = thunkAPI.getState()
  const text = {
    "text": state.fragen.suchFilter
  }
  const target = state.fragen.spiel
  const message = await axios.post(`http://127.0.0.1:8000/trinkspiele/${target}_list/`, text)
    .then(res => thunkAPI.fulfillWithValue(res.data.text))
    .catch(err => thunkAPI.rejectWithValue(err.data.text))
  return message
})

export const writeDateToFile = createAsyncThunk('fragen/writeDateToFile', async (nothing, thunkAPI) => {
  const state = thunkAPI.getState()
  var target = state.fragen.spiel
  if (target === "nochnie") {
    target = "100"
  }
  else if (target === "eher") {
    target = "200"
  }
  else if (target === "wahrheit") {
    target = "300"
  }
  else if (target === "pflicht") {
    target = "400"
  }
  const text = {
    "text": target
  }
  const message = await axios.post("http://127.0.0.1:8000/trinkspiele/date/", text)
    .then(res => thunkAPI.fulfillWithValue(res.data.text))
    .catch(err => thunkAPI.rejectWithValue(err.data.text))
  return message
})

export const fragenSlice = createSlice({
  name: "fragen",
  initialState,
  reducers: {
    setNextNochnie(state, action) {
      state.amount = state.amount + action.payload
      state.nochnie = state.randomNochnie[state.amount]
    },
    setNextEher(state, action) {
      state.amount = state.amount + action.payload
      state.eher = state.randomEher[state.amount]
    },
    setNextWahrheit(state, action) {
      state.wahrheit = state.allWahrheit[state.allWahrheit.length * Math.random() | 0]
      state.visibleWahrheit = initialState.visibleWahrheit
      state.visibleWahrheit2 = initialState.visibleWahrheit2
      state.visibleTrinkanzahl = initialState.visibleTrinkanzahl
      state.disable = initialState.disable
      state.trinkanzahl = initialState.trinkanzahl
    },
    setNextPflicht(state, action) {
      state.pflicht = state.allPflicht[state.allPflicht.length * Math.random() | 0]
      state.visiblePflicht = initialState.visiblePflicht
      state.visiblePflicht2 = initialState.visiblePflicht2
      state.visibleTrinkanzahl = initialState.visibleTrinkanzahl
      state.disable = initialState.disable
      state.trinkanzahl = initialState.trinkanzahl
    },
    changeVisibilityWahrheit(state, action) {
      state.visibleWahrheit = true
      state.visibleWahrheit2 = false
      state.disable = false
    },
    changeVisibilityPflicht(state, action) {
      state.visiblePflicht = true
      state.visiblePflicht2 = false
      state.disable = false
    },
    chooseTrinkanzahl(state, action) {
      state.trinkanzahl = randomNumbers[randomNumbers.length * Math.random() | 0]
    },
    changeVisibilityTrinkanzahl(state, action) {
      state.visibleTrinkanzahl = false
      state.visibleWahrheit = true
      state.visibleWahrheit2 = initialState.visibleWahrheit2
      state.visiblePflicht = true
      state.visiblePflicht2 = initialState.visiblePflicht2
    },
    filterSearch(state, action) {
      state.suchFilter = action.payload
    },
    changeSpiel(state, action) {
      state.spiel = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRandomNochnie.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRandomNochnie.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.randomNochnie = action.payload
        state.nochnie = state.randomNochnie[0]
        state.amount = 0
      })
      .addCase(fetchRandomNochnie.rejected, (state) => {
        state.status = "idle";
        state.randomNochnie = []
        state.nochnie = {}
      })
      .addCase(fetchAllNochnie.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllNochnie.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allNochnie = action.payload
      })
      .addCase(fetchAllNochnie.rejected, (state) => {
        state.status = "idle";
        state.allNochnie = []
      })
      .addCase(fetchRandomEher.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRandomEher.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.randomEher = action.payload
        state.eher = state.randomEher[0]
        state.amount = 0
      })
      .addCase(fetchRandomEher.rejected, (state) => {
        state.status = "idle";
        state.randomEher = []
      })
      .addCase(fetchAllEher.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllEher.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allEher = action.payload
      })
      .addCase(fetchAllEher.rejected, (state) => {
        state.status = "idle";
        state.allEher = []
      })
      .addCase(fetchAllWahrheit.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllWahrheit.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allWahrheit = action.payload
        state.wahrheit = state.allWahrheit[state.allWahrheit.length * Math.random() | 0]
      })
      .addCase(fetchAllWahrheit.rejected, (state) => {
        state.status = "idle";
        state.allWahrheit = []
        state.wahrheit = {}
      })
      .addCase(fetchAllPflicht.pending, (state) => {
        state.status2 = "loading";
      })
      .addCase(fetchAllPflicht.fulfilled, (state, action) => {
        state.status2 = "succeeded";
        state.allPflicht = action.payload
        state.pflicht = state.allPflicht[state.allPflicht.length * Math.random() | 0]
      })
      .addCase(fetchAllPflicht.rejected, (state) => {
        state.status2 = "idle";
        state.allPflicht = []
        state.pflicht = {}
      })
      .addCase(writeToFile.pending, (state) => {
        state.status = "idle";
      })
      .addCase(writeToFile.fulfilled, (state) => {
        toast.success("Frage wurde zur Datei hinzugefügt")
        state.status = "succeeded";
        state.suchFilter = initialState.suchFilter
      })
      .addCase(writeToFile.rejected, (state) => {
        toast.error("Die Frage konnte nicht hinzugefügt werden")
        state.status = "idle";
      })
      .addCase(writeDateToFile.pending, (state) => {
        state.status = "idle";
      })
      .addCase(writeDateToFile.fulfilled, (state) => {
        toast.success("Datum wurde zur Datei hinzugefügt")
        state.status = "succeeded";
      })
      .addCase(writeDateToFile.rejected, (state) => {
        toast.error("Datum konnte nicht hinzugefügt werden")
        state.status = "idle";
      })
  }
})

export const {
  setNextNochnie,
  setNextEher,
  setNextWahrheit,
  setNextPflicht,
  changeVisibilityWahrheit,
  changeVisibilityPflicht,
  chooseTrinkanzahl,
  changeVisibilityTrinkanzahl,
  filterSearch,
  changeSpiel,
} = fragenSlice.actions

export default fragenSlice.reducer