import React, { useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { showModal2, showModal3, showModal5 } from '../slices/modalSlice';
import ReactGA from 'react-ga'
import randomCodeGenerator2 from '../utils/randomCodeGenerator2'
import { changeRoomId, changeId, changeUsername, getRoom, verlassen, speicherNamenFuer, setNameBesetzt } from '../slices/socketSlice'
import { SocketContext } from '../context/socket';
import SpielraumModal from './SpielraumModal';
import SpielraumModal2 from './SpielraumModal2';
import { toast } from 'react-toastify';
import SpielraumModal3 from './SpielraumModal3';
import { BrowserView, MobileView } from 'react-device-detect';

const WerBinIch = () => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const { id, username, roomId, room, werHatVerlassen, verlassenBool } = useSelector((state) => state.socket)

  useEffect(() => {
    if (username === '' || username.length < 1) {
      dispatch(showModal5(true))
    }
    socket.on('deineId', (id) => {
      dispatch(changeId(id))
    })
    socket.on('room', (room) => {
      dispatch(getRoom(room))
    })

    return () => {
      socket.on('roomFull', () => {
        toast.error('Raum ist schon voll')
        dispatch(changeRoomId(''))
      })
      socket.on('nameBesetzt', () => {
        dispatch(setNameBesetzt(true))
        dispatch(changeRoomId(''))
      })
      socket.on('speicherNamenFuer', (speicherNamen) => {
        dispatch(speicherNamenFuer(speicherNamen))
        dispatch(showModal3())
      })
      socket.on('left', (data) => {
        verlassenMsg(data)
      })
    }
  }, [username, verlassenBool, werHatVerlassen, socket, dispatch]);


  const verlassenMsg = (data) => {
    toast.error(data.username + ' hat den Raum verlassen')
  }
  const send = () => {
    const roomId = randomCodeGenerator2(5)
    dispatch(changeRoomId(roomId))
    const data = {
      roomId: roomId,
      username: username
    }
    socket.emit('createRoom', data)
  }

  const raumVerlassen = (id) => {
    var users = room.users.filter(user => user.id !== id)
    var roomData = {
      roomId: roomId,
      users: users
    }

    var userData = {
      roomId: roomId,
      username: username
    }

    if (users.length === 0) {
      roomData = {
        roomId: roomId,
        users: [
          {
            id: "",
            username: "",
            werbinich: ""
          }
        ]
      }
    }

    const data = roomData
    const data2 = userData
    socket.emit('leave', data)
    socket.emit('left', data2)
    toast.success('Du hast den Raum verlassen')
  }

  const zufaelligZuweisen = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Zufällig Personen zuweisen'
    })

    const data = {
      roomId: roomId
    }
    socket.emit('zuweisen', data)
  }

  const auswaehlen = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Selbst Personen zuweisen'
    })

    const data = {
      roomId: roomId
    }
    socket.emit('auswaehlen', data)
  }

  return (
    <div>
      <Container className="col col-8 page-content-bg">
        <BrowserView>
          <Row className="pt-3 padding-overall">
            <Col className='col-button'>
              <Button className='btn btn-bg-primary btn-werbinich'
                disabled={username.length === 0 || roomId.length > 0}
                onClick={() => {
                  dispatch(changeUsername(username))
                  send()
                }}>
                Raum erstellen
              </Button>
            </Col>
            <Col className='col-button'>
              <Button className='btn btn-bg-primary btn-werbinich'
                disabled={username.length === 0 || roomId.length > 0}
                onClick={() => dispatch(showModal2())}>
                Raum beitreten
              </Button>
            </Col>
            <Col className='col-button'>
              <Button className='btn btn-bg-schließen btn-werbinich'
                disabled={roomId.length === 0}
                onClick={() => {
                  raumVerlassen(id)
                  dispatch(verlassen())
                }}>
                Raum verlassen
              </Button>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col>
              <div className="spiel-cards-werbinich">
                <Row>
                  <Col className=''>
                    <p>Raum:</p>
                    <p>{roomId ? roomId : ""}</p>
                  </Col>
                  <Col className='float-left'>
                    <p>Ersteller: </p>
                    <p>{room.users[0].username !== "" ? room.users[0].username : ""}</p>
                  </Col>
                  <Col className='float-right'>
                    <p>Spieler:</p>
                    <p>{username}</p>
                  </Col>
                </Row>
                <div className='divider-grey mt-1'></div>
                <Row className='mt-3'>
                  <Col>
                    <h5>Wer bin ich?</h5>
                  </Col>
                  <Col>
                    <p className='float-right'>{room.users[0].id === "" ? 0 : room.users.length}/10</p>
                  </Col>
                </Row>
                {room.users.map(user =>
                  <Row key={user.id}>
                    <Col>
                      <p
                        className="fontSizeLarger fontWeightLighter content-after mb-0"
                      >{user.username === "" &&
                        (user.werbinich.text === "" || user.werbinich.text === undefined) ? "" :
                        user.username.length > 0 && user.werbinich.text === undefined ? user.username :
                          user.username.length > 0 && user.username === username && user.werbinich.text.length > 0 ? user.username + " : **********" :
                            user.username + " : " + user.werbinich.text} <a key={user.id} hidden={username === user.username || (user.werbinich.info === undefined || user.werbinich.info === '')} href={user.werbinich.info} target="_blank" rel="noreferrer">Info</a>
                      </p>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
          <Row className='pt-3 padding-overall'>
            <Col>
              <Button
                hidden={room.users[0].username !== username || room.users[0].username === ''}
                disabled={room.users.length < 2}
                className="btn btn-bg-primary width-200"
                onClick={() => zufaelligZuweisen()}
                size="lg"
              >Zufällig zuweisen</Button>
            </Col>
            <Col>
              <Button
                hidden={room.users[0].username !== username || room.users[0].username === ''}
                disabled={room.users.length < 2}
                className="btn btn-bg-primary width-200"
                onClick={() => {
                  auswaehlen()
                }}
                size="lg"
              >Selbst auswählen</Button>
            </Col>
          </Row>
        </BrowserView>

        <MobileView>
          <Row className="pt-3 padding-overall">
            <Col className='col-button'>
              <Button className='btn btn-bg-primary btn-werbinich'
                disabled={username.length === 0 || roomId.length > 0}
                onTouchStart={() => {
                  dispatch(changeUsername(username))
                  send()
                }}>
                Raum erstellen
              </Button>

            </Col>
            <Col className='col-button'>
              <Button className='btn btn-bg-primary btn-werbinich'
                disabled={username.length === 0 || roomId.length > 0}
                onTouchStart={() => dispatch(showModal2())}>
                Raum beitreten
              </Button>
            </Col>
            <Col className='col-button'>
              <Button className='btn btn-bg-schließen btn-werbinich'
                disabled={roomId.length === 0}
                onTouchStart={() => {
                  raumVerlassen(id)
                  dispatch(verlassen())
                }}>
                Raum verlassen
              </Button>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col>
              <div className="spiel-cards-werbinich">
                <Row>
                  <Col className=''>
                    <p>Raum:</p>
                    <p>{roomId ? roomId : ""}</p>
                  </Col>
                  <Col className='float-left'>
                    <p>Ersteller: </p>
                    <p>{room.users[0].username !== "" ? room.users[0].username : ""}</p>
                  </Col>
                  <Col className='float-right'>
                    <p>Spieler:</p>
                    <p>{username}</p>
                  </Col>
                </Row>
                <div className='divider-grey mt-1'></div>
                <Row className='mt-3'>
                  <Col>
                    <h5>Wer bin ich?</h5>
                  </Col>
                  <Col>
                    <p className='float-right'>{room.users[0].id === "" ? 0 : room.users.length}/10</p>
                  </Col>
                </Row>
                {room.users.map(user =>
                  <Row key={user.id}>
                    <Col>
                      <p
                        className="fontSizeLarger fontWeightLighter content-after mb-0"
                      >{user.username === "" &&
                        (user.werbinich.text === "" || user.werbinich.text === undefined) ? "" :
                        user.username.length > 0 && user.werbinich.text === undefined ? user.username :
                          user.username.length > 0 && user.username === username && user.werbinich.text.length > 0 ? user.username + " : **********" :
                            user.username + " : " + user.werbinich.text} <a key={user.id} hidden={username === user.username || (user.werbinich.info === undefined || user.werbinich.info === '')} href={user.werbinich.info} target="_blank" rel="noreferrer">Info</a>
                      </p>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
          <Row className='pt-3 padding-overall'>
            <Col>
              <Button
                hidden={room.users[0].username !== username || room.users[0].username === ''}
                disabled={room.users.length < 2}
                className="btn btn-bg-primary width-200"
                onTouchStart={() => zufaelligZuweisen()}
                size="lg"
              >Zufällig zuweisen</Button>
            </Col>
            <Col>
              <Button
                hidden={room.users[0].username !== username || room.users[0].username === ''}
                disabled={room.users.length < 2}
                className="btn btn-bg-primary width-200"
                onTouchStart={() => {
                  auswaehlen()
                }}
                size="lg"
              >Selbst auswählen</Button>
            </Col>
          </Row>
        </MobileView>
      </Container>
      <SpielraumModal />
      <SpielraumModal2 />
      <SpielraumModal3 />
    </div>
  )
}
export default WerBinIch