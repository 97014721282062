import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Container, Form, Table, Row, Col } from 'react-bootstrap'
import { changeSpiel, fetchAllEher, fetchAllNochnie, fetchAllPflicht, fetchAllWahrheit, filterSearch, writeDateToFile, writeToFile } from '../slices/fragenSlice';

const FragenAdmin = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.fragen.status)
  const { allNochnie, allEher, allWahrheit, allPflicht, suchFilter, spiel } = useSelector((state) => state.fragen)
  const filter = (e) => {
    dispatch(filterSearch(e.target.value))
  }

  const spielwaehlen = (spiel) => {
    dispatch(changeSpiel(spiel))
  }

  const writer = () => {
    dispatch(writeToFile())
  }

  const addDate = () => {
    dispatch(writeDateToFile())
  }

  if (status === 'idle') {
    dispatch(fetchAllNochnie())
    dispatch(fetchAllEher())
    dispatch(fetchAllWahrheit())
    dispatch(fetchAllPflicht())
  }


  return (
    <Container>
      <Form className="mt-3 mb-3">
        <Form.Group>
          <Form.Check
            type="radio"
            label="Ich hab noch nie"
            name="spiele"
            value="nochnie"
            onClick={(e) => spielwaehlen(e.target.value)}
          />
          <Form.Check
            type="radio"
            label="Wer würde eher"
            name="spiele"
            value="eher"
            onClick={(e) => spielwaehlen(e.target.value)}
          />
          <Form.Check
            type="radio"
            label="Wahrheit"
            name="spiele"
            value="wahrheit"
            onClick={(e) => spielwaehlen(e.target.value)}
          />
          <Form.Check
            type="radio"
            label="Pflicht"
            name="spiele"
            value="pflicht"
            onClick={(e) => spielwaehlen(e.target.value)}
          />
          <Form.Control
            onChange={filter}
            as="textarea"
            rows="2"
            name="suchFilter"
            value={suchFilter}
            className="mb-3 mt-3 pt-3 pb-3"
            type="text" />
          <Button disabled={(suchFilter.length === 0 || spiel.length === 0)} onClick={writer}>Speichern</Button>
          <Button disabled={(suchFilter.length > 0 || spiel.length === 0)} className="float-right" variant="info" onClick={addDate}>Datum hinzufügen</Button>
        </Form.Group>
      </Form>
      <Row>
        <Col>
          <Table className="mb-3">
            <thead>
              <tr>
                <td>ID</td>
                <td>Ich hab noch nie</td>
              </tr>
            </thead>
            <tbody>
              {allNochnie.filter(nochnie => nochnie.text.toLowerCase().includes(suchFilter.toLowerCase())).map(nochnie =>
                <tr key={nochnie.id}>
                  <td>{nochnie.id}</td>
                  <td>{nochnie.text}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
        <Col>
          <Table className="mb-3">
            <thead>
              <tr>
                <td>ID</td>
                <td>Wer würde eher</td>
              </tr>
            </thead>
            <tbody>
              {allEher.filter(eher => eher.text.toLowerCase().includes(suchFilter.toLowerCase())).map((eher) =>
                <tr key={eher.id}>
                  <td>{eher.id}</td>
                  <td>{eher.text}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table className="mb-3">
            <thead>
              <tr>
                <td>ID</td>
                <td>Wahrheit</td>
              </tr>
            </thead>
            <tbody>
              {allWahrheit.filter(wahrheit => wahrheit.text.toLowerCase().includes(suchFilter.toLowerCase())).map((wahrheit) =>
                <tr key={wahrheit.id}>
                  <td>{wahrheit.id}</td>
                  <td>{wahrheit.text}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
        <Col>
          <Table className="">
            <thead>
              <tr>
                <td>ID</td>
                <td>Pflicht</td>
              </tr>
            </thead>
            <tbody>
              {allPflicht.filter(pflicht => pflicht.text.toLowerCase().includes(suchFilter.toLowerCase())).map((pflicht) =>
                <tr key={pflicht.id}>
                  <td>{pflicht.id}</td>
                  <td>{pflicht.text}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}
export default FragenAdmin