import React from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect'
import ReactGA from 'react-ga'

const Header = () => {
  const googleAnalyticsSpiele = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Über Header: Zur Spiele-Seite wechseln'
    })
  }
  const googleAnalyticsNochnie = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Über Header: Zur Ich-hab-noch-nie-Seite wechseln'
    })
  }
  const googleAnalyticsEher = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Über Header: Zur Wer-würde-eher-Seite wechseln'
    })
  }
  const googleAnalyticsWahrheitPflicht = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Über Header: Zur Wahrheit-oder-Pflicht-Seite wechseln'
    })
  }
  const googleAnalyticsBusfahrer = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Über Header: Zur Busfahrer-Seite wechseln'
    })
  }
  const googleAnalyticsWerBinIch = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Über Header: Zur Wer-bin-ich-Seite wechseln'
    })
  }
  const googleAnalyticsHoeherTiefer = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Über Header: Zur Höher-oder-Tiefer-Seite wechseln'
    })
  }
  const googleAnalyticsBang = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Über Header: Zur Bang-Seite wechseln'
    })
  }
  return (
    <div>
      <Navbar className="navbar mr-2 navbar-custom navbar-light" expand="sm">
        <Container className="col col-8">
          <Navbar.Brand className="ml-1" href="/">Trinkspielplatz</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/">Homepage</Nav.Link>
              <NavDropdown title="Spiele">
                <BrowserView>
                  <NavDropdown.Item onClick={googleAnalyticsSpiele} href="/spiele">Übersicht Spiele</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={googleAnalyticsNochnie} href="/nochnie">Ich hab noch nie</NavDropdown.Item>
                  <NavDropdown.Item onClick={googleAnalyticsEher} href="/eher">Wer würde eher</NavDropdown.Item>
                  <NavDropdown.Item onClick={googleAnalyticsWahrheitPflicht} href="/wahrheitpflicht">Wahrheit oder Pflicht</NavDropdown.Item>
                  <NavDropdown.Item onClick={googleAnalyticsBusfahrer} href="/busfahrer">Busfahrer</NavDropdown.Item>
                  <NavDropdown.Item onClick={googleAnalyticsWerBinIch} href="/werbinich">Wer bin ich</NavDropdown.Item>
                  <NavDropdown.Item onClick={googleAnalyticsHoeherTiefer} href="/hoehertiefer">Höher oder Tiefer</NavDropdown.Item>
                  <NavDropdown.Item onClick={googleAnalyticsBang} href="/bang">BANG!</NavDropdown.Item>
                </BrowserView>

                <MobileView>
                  <NavDropdown.Item onTouchStart={googleAnalyticsSpiele} href="/spiele">Übersicht Spiele</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onTouchStart={googleAnalyticsNochnie} href="/nochnie">Ich hab noch nie</NavDropdown.Item>
                  <NavDropdown.Item onTouchStart={googleAnalyticsEher} href="/eher">Wer würde eher</NavDropdown.Item>
                  <NavDropdown.Item onTouchStart={googleAnalyticsWahrheitPflicht} href="/wahrheitpflicht">Wahrheit oder Pflicht</NavDropdown.Item>
                  <NavDropdown.Item onTouchStart={googleAnalyticsBusfahrer} href="/busfahrer">Busfahrer</NavDropdown.Item>
                  <NavDropdown.Item onTouchStart={googleAnalyticsWerBinIch} href="/werbinich">Wer bin ich</NavDropdown.Item>
                  <NavDropdown.Item onTouchStart={googleAnalyticsHoeherTiefer} href="/hoehertiefer">Höher oder Tiefer</NavDropdown.Item>
                  <NavDropdown.Item onTouchStart={googleAnalyticsBang} href="/bang">BANG!</NavDropdown.Item>
                </MobileView>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="divider"></div>
    </div >
  )
}
export default Header