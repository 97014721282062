import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { fetchRandomNochnie, setNextNochnie } from '../slices/fragenSlice'
import { BrowserView, MobileView } from 'react-device-detect'
import ReactGA from 'react-ga'

const Nochnie = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.fragen.status)
  if (status === 'idle') {
    dispatch(fetchRandomNochnie())
  }
  const { randomNochnie, nochnie, amount } = useSelector((state) => state.fragen)
  const weiterNochnie = (e) => {
    ReactGA.event({
      category: 'Button',
      action: 'Nächste Frage bei Ich-hab-noch-nie'
    })
    dispatch(setNextNochnie(1))
  }
  return (
    <Container className="col col-8 page-content-bg">
      <BrowserView>
        <Row className="pt-3">
          <Col>
            <div className="spiel-cards" onClick={weiterNochnie}>
              <h5>Ich hab noch nie...</h5>
                <div hidden={status === 'loading'}>
                  <p className="mt-4 height140 fontSizeLarger fontWeightLighter content-after" value={nochnie.text}></p>
                  <Button hidden={amount === randomNochnie.length - 1} className="btn btn-bg-primary width-200 shadow" size="lg">Weiter</Button>
                  <Button hidden={amount !== randomNochnie.length - 1} className="btn btn-bg-secondary width-200 shadow" href="/spiele" size="lg">Beenden</Button>
                </div>
                <div hidden={status !== 'loading'} className="spinner-border loading mt-5" role="status"></div>
            </div>
          </Col>
        </Row>
      </BrowserView>
      
      <MobileView>
        <Row className="pt-3">
          <Col>
            <div className="spiel-cards" onTouchStart={weiterNochnie}>
              <h5>Ich hab noch nie...</h5>
                <div hidden={status === 'loading'}>
                  <p className="mt-4 height140 fontSizeLarger fontWeightLighter content-after" value={nochnie.text}></p>
                  <Button hidden={amount === randomNochnie.length - 1} className="btn btn-bg-primary width-200 shadow" size="lg">Weiter</Button>
                  <Button hidden={amount !== randomNochnie.length - 1} className="btn btn-bg-secondary width-200 shadow" href="/spiele" size="lg">Beenden</Button>
                </div>
                <div hidden={status !== 'loading'} className="spinner-border loading mt-5" role="status"></div>
            </div>
          </Col>
        </Row>
      </MobileView>
    </Container>
  )
}
export default Nochnie