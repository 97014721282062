import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap';

const Footer = () => {
  return (
    <div>
      <div className="divider"></div>
      <div className="footer">
        <Navbar className="navbar mr-2 navbar-custom navbar-light" expand="sm">
          <Container className="col col-8">
            <Nav className="mr-auto">
              <Nav.Link href="/">Homepage</Nav.Link>
              <Nav.Link href="/kontakt">Kontakt</Nav.Link>
              <Nav.Link href="/datenschutz">Datenschutz</Nav.Link>
              {/* <Nav.Link href="/shop">Shop</Nav.Link> */}
            </Nav>
          </Container>
        </Navbar>
      </div>
    </div>
  )
}
export default Footer