import React from 'react'
import { useSelector } from 'react-redux'


const Card = (props) => {
  const { number, deck } = useSelector(state => state.card)

  return (
    <div>
      <img
        src={deck[number].card}
        width={`${props.width}`}
        alt="angezeigte Spielkarte"
        className='shadow' />
    </div>
  )
}
export default Card