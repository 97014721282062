import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { showModal1 } from '../slices/modalSlice';
import { BrowserView, MobileView } from 'react-device-detect'

import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

const AnleitungsModal = () => {
  const dispatch = useDispatch();
  const { modal1, modalText } = useSelector(state => state.modal)
  return (
    <div>
      <Modal
        show={modal1}
        onHide={() => dispatch(showModal1())}
        centered
      >
        <BrowserView>
          <Modal.Header closeButton>
            <Modal.Title>
              Anleitung
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalText}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => dispatch(showModal1())} className="btn btn-bg-schließen">
              <ClearOutlinedIcon />
            </Button>
          </Modal.Footer>
        </BrowserView>

        <MobileView>
          <Modal.Header closeButton>
            <Modal.Title>
              Anleitung
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalText}
          </Modal.Body>
          <Modal.Footer>
            <Button onTouchStart={() => dispatch(showModal1())} className="btn btn-bg-schließen">
              <ClearOutlinedIcon />
            </Button>
          </Modal.Footer>
        </MobileView>
      </Modal>
    </div>
  )
}
export default AnleitungsModal