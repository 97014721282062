import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: "idle",
  started: false
}



export const bangSlice = createSlice({
  name: "bang",
  initialState,
  reducers: {
    setStarted(state, action) {
      state.started = action.payload
    }
  },
})

export const {
  setStarted
} = bangSlice.actions

export default bangSlice.reducer