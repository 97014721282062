import React, { useRef } from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { showModal5 } from '../slices/modalSlice';
import { changeUsername } from '../slices/socketSlice';
import { BrowserView, MobileView } from 'react-device-detect';


const SpielraumModal = () => {
  const dispatch = useDispatch();
  const { modal5 } = useSelector(state => state.modal)
  const { username } = useSelector((state) => state.socket)
  const textInput = useRef()

  return (
    <Modal
      show={modal5}
      onShow={() => { textInput.current.focus() }}
      centered
    >
      <BrowserView>
        <Modal.Header>
          <Modal.Title>
            Spielername
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className=''>
              <Form.Control ref={textInput} type="text" value={username} onChange={(e) => dispatch(changeUsername(e.target.value))} placeholder="Dein Spielername" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-bg-primary"
            disabled={!(username.length > 0)}
            onClick={() => {
              dispatch(changeUsername(username))
              dispatch(showModal5(false))
            }}>Spieler erstellen</Button>
        </Modal.Footer>
      </BrowserView>

      <MobileView>
        <Modal.Header>
          <Modal.Title>
            Spielername
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className=''>
              <Form.Control ref={textInput} type="text" value={username} onChange={(e) => dispatch(changeUsername(e.target.value))} placeholder="Dein Spielername" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-bg-primary"
            disabled={!(username.length > 0)}
            onTouchStart={() => {
              dispatch(changeUsername(username))
              dispatch(showModal5(false))
            }}>Spieler erstellen</Button>
        </Modal.Footer>
      </MobileView>
    </Modal>

  )
}
export default SpielraumModal