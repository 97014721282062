import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { socket } from '../context/socket'
import Back from '../poker-qr/Back.svg'
import { changeChecked, changeTrinkanzahl } from '../slices/busfahrerSlice'
import { BrowserView, MobileView } from 'react-device-detect';


const BusfahrerCard = (props) => {
  const { deck, roomId, roomBusfahrer } = useSelector(state => state.busfahrer)
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on('gedrehteKarte', (data) => {
      karteDrehen(data)
    })
    socket.on('inMeinenKarten', (data) => {
      dispatch(changeChecked(data.result))
      dispatch(changeTrinkanzahl(data))
    })
  });


  const checker = (id, rowValue) => {
    const data = {
      roomId: roomId,
      roomBusfahrer: roomBusfahrer,
      id: id,
      cardValue: deck[id].value,
      rowValue: rowValue,
    }
    socket.emit("karteDrehen", data)
  }

  const karteDrehen = (data) => {
    if (data.id > 0 && document.getElementById(data.id - 1).checked === false) {
      document.getElementById(data.id).checked = false
    }
    else if (document.getElementById(data.id).checked === false) {
      document.getElementById(data.id).checked = true
    }
    else {
      document.getElementById(data.id).checked = true
    }
  }

  

  return (
    <label className="card" htmlFor={`${props.id}`}>
      <BrowserView>
        <input type="checkbox" id={`${props.id}`} onClick={() => checker(props.id, props.rowValue)} />
        <div className="back">
          <img src={Back} width={`${props.width}`} alt="Rücken Spielkarte" />
        </div>
        <div className="front">
          <img src={deck[props.id].card} width={props.width} alt="angezeigte Spielkarte" />
        </div>
      </BrowserView>

      <MobileView>
        <input type="checkbox" id={`${props.id}`} onTouchStart={() => checker(props.id, props.rowValue)} />
        <div className="back">
          <img src={Back} width={`${props.width}`} alt="Rücken Spielkarte" />
        </div>
        <div className="front">
          <img src={deck[props.id].card} width={props.width} alt="angezeigte Spielkarte" />
        </div>
      </MobileView>
    </label>
  )
}
export default BusfahrerCard