import React from 'react'
import { Button, Col, Container, Row, Image } from 'react-bootstrap'
import ReactGA from 'react-ga'
import image from '../images/Homepage.svg'
// import image2 from '../images/Homepage-Right.svg'
import badge from '../images/google-play-badge.png'

const Hompage = () => {
  const googleAnalyticsSpiele = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Zur Spiele-Seite wechseln'
    })
  }

  return (
    <div className="page-content">
      <Row className="disable-padding image-container">
        <Col className="image-container">
          <div className="font-over-img">
            Deine Seite Nr.1 für <br></br>Trink- und Partyspiele
          </div>
          <Image src={image} fluid />
        </Col>
      </Row>

      <div className="divider"></div>
      <Container className="">
        <Row className="pt-3 justify-content-center">
          <Col className="col-8">
            <div className="jumbotron mb-5">
              <h1 className="display-4">Hallo, Freunde!</h1>
              <p className="lead">Sorry, die Homepage befindet sich noch im Aufbau.</p>
              <hr className="my-4"></hr>
              <p>Ihr könnt aber schon ein paar Spiele ausprobieren, mit der Zeit werden auch noch welche hinzukommen</p>
              <p className="lead p-3">
                <Button href="/spiele" onClick={googleAnalyticsSpiele} className="btn btn-bg-primary width-200" size='lg'>Zu den Spielen</Button>
              </p>
              <p className='lead mt-5'>
                Oder downloade dir Trinkspielplatz für dein Android-Smartphone
              </p>
              <Col>
                <a href='https://play.google.com/store/apps/details?id=com.charbelchougourou.trinkspielplatz&gl=DE&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><Image src={badge} className='width-6' fluid /></a>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default Hompage
