import React from 'react'
import { useSelector } from 'react-redux'
import Back from '../poker-qr/Back.svg'
import { BrowserView, MobileView } from 'react-device-detect';




const BusfahrerCardFaceUp = (props) => {
  const { deck } = useSelector(state => state.busfahrer)

  return (
    <label className="card" htmlFor={`${props.id}`}>
      <BrowserView>
        <input type="checkbox" checked={props.checked} id={`${props.id}`} onChange={() => { }} />
        <div className="back">
          <div className='centered'>{props.trinkanzahl}</div>
          <img src={Back} width={props.width} alt="Rücken Spielkarte" />
        </div>
        <div className="front">
          <img className='myCards' src={deck[props.id !== undefined ? props.id : 0].card} width={`${props.width}`} alt="angezeigte Spielkarte" />
        </div>
      </BrowserView>

      <MobileView>
        <input type="checkbox" checked={props.checked} id={`${props.id}`} onChange={() => { }} />
        <div className="back">
          <div className='centered'>{props.trinkanzahl}</div>
          <img src={Back} width={props.width} alt="Rücken Spielkarte" />
        </div>
        <div className="front">
          <img className='myCards' src={deck[props.id !== undefined ? props.id : 0].card} width={`${props.width}`} alt="angezeigte Spielkarte" />
        </div>
      </MobileView>
    </label>
  )
}
export default BusfahrerCardFaceUp