import { configureStore } from '@reduxjs/toolkit';
import fragenReducer from './slices/fragenSlice'
import modalReducer from './slices/modalSlice'
import kontaktReducer from './slices/kontaktSlice'
import socketReducer from './slices/socketSlice'
import cardReducer from './slices/cardSlice'
import busfahrerReducer from './slices/busfahrerSlice'
import bangReducer from './slices/bangSlice'


const store = configureStore({
  reducer: {
    fragen: fragenReducer,
    modal: modalReducer,
    kontakt: kontaktReducer,
    socket: socketReducer,
    card: cardReducer,
    busfahrer: busfahrerReducer,
    bang: bangReducer,
  }, 
});
export default store