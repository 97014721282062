import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Card from './Card'
import { checkGleich, checkHoeher, checkTiefer, getRandomDeck, resetCorrectInRow, setRandomCard } from '../slices/cardSlice'
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import DragHandle from '@material-ui/icons/DragHandle';
import { BrowserView, MobileView } from 'react-device-detect';

const HoeherTiefer = () => {
  const dispatch = useDispatch();
  const { status, deck, nextCard, currentCard, correctInRow, check, number } = useSelector((state) => state.card)
  const cardsForCheck = {
    nextCard,
    currentCard
  }

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getRandomDeck(deck))
    }
  }, [status, deck, dispatch])

  return (
    <Container className="col col-8 page-content-bg">
      <BrowserView>
        <Row className="pt-3 padding-overall">
          <Col>
            <div className="spiel-cards-hoeher-tiefer">
              <Row>
                <p>Verbleibende Karten: {51 - number}</p>
              </Row>
              <Card width={150} />
              {number < 51 ?
                <Row className='mt-4'>
                  <Col>
                    <Button
                      onClick={() => {
                        if (correctInRow === 3) {
                          dispatch(resetCorrectInRow())
                        }
                        dispatch(setRandomCard())
                        dispatch(checkHoeher(cardsForCheck))
                      }}
                      className="btn btn-bg-primary shadow" size="lg">
                      <ArrowUpward name="svg" />
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        if (correctInRow === 3) {
                          dispatch(resetCorrectInRow())
                        }
                        dispatch(setRandomCard())
                        dispatch(checkGleich(cardsForCheck))
                      }}
                      className="btn btn-bg-primary shadow" size="lg">
                      <DragHandle name="svg" />
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        if (correctInRow === 3) {
                          dispatch(resetCorrectInRow())
                        }
                        dispatch(setRandomCard())
                        dispatch(checkTiefer(cardsForCheck))
                      }}
                      className="btn btn-bg-primary shadow" size="lg">
                      <ArrowDownward name="svg" />
                    </Button>
                  </Col>
                </Row>
                :
                <Row className='mt-3'>
                  <Col>
                    <Button className="mt-3 btn btn-bg-primary" onClick={() => dispatch(getRandomDeck())} onTouchStart={() => dispatch(getRandomDeck())} size="lg">Stapel mischen</Button>
                  </Col>
                </Row>
              }
              <Row className="mt-4">
                <Col>
                  <p className={check ? 'font-size-30 text-correct' : 'font-size-30 text-false'}>{check === undefined ? "" : check ? "Richtig!" : !check ? "Falsch!" : ""}</p>
                </Col>
                <Col>
                  <p className=''>{"Richtig in Folge: \n" + correctInRow}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className=''>{correctInRow === 3 ? 'Nächster Spieler!' : ''}</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </BrowserView>
      
      <MobileView>
        <Row className="pt-3 padding-overall">
          <Col>
            <div className="spiel-cards-hoeher-tiefer">
              <Row>
                <p>Verbleibende Karten: {51 - number}</p>
              </Row>
              <Card width={150} />
              {number < 51 ?
                <Row className='mt-4'>
                  <Col>
                    <Button
                      onTouchStart={() => {
                        if (correctInRow === 3) {
                          dispatch(resetCorrectInRow())
                        }
                        dispatch(setRandomCard())
                        dispatch(checkHoeher(cardsForCheck))
                      }}
                      className="btn btn-bg-primary" size="lg">
                      <ArrowUpward name="svg" />
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onTouchStart={() => {
                        if (correctInRow === 3) {
                          dispatch(resetCorrectInRow())
                        }
                        dispatch(setRandomCard())
                        dispatch(checkGleich(cardsForCheck))
                      }}
                      className="btn btn-bg-primary" size="lg">
                      <DragHandle name="svg" />
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onTouchStart={() => {
                        if (correctInRow === 3) {
                          dispatch(resetCorrectInRow())
                        }
                        dispatch(setRandomCard())
                        dispatch(checkTiefer(cardsForCheck))
                      }}
                      className="btn btn-bg-primary" size="lg">
                      <ArrowDownward name="svg" />
                    </Button>
                  </Col>
                </Row>
                :
                <Row className='mt-3'>
                  <Col>
                    <Button className="mt-3 btn btn-bg-primary" onTouchStart={() => dispatch(getRandomDeck())} size="lg">Stapel mischen</Button>
                  </Col>
                </Row>
              }
              <Row className="mt-4">
                <Col>
                  <p className={check ? 'font-size-30 text-correct' : 'font-size-30 text-false'}>{check === undefined ? "" : check ? "Richtig!" : !check ? "Falsch!" : ""}</p>
                </Col>
                <Col>
                  <p className=''>{"Richtig in Folge: \n" + correctInRow}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>{correctInRow === 3 ? 'Nächster Spieler!' : ''}</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </MobileView>
    </Container>
  )
}
export default HoeherTiefer