import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { showModal1 } from '../slices/modalSlice'
import { 
  ichHabNochNieAnleitung, 
  werWuerdeEherAnleitung, 
  wahrheitOderPflichtAnleitung, 
  busfahrerAnleitung, 
  werBinIchAnleitung, 
  hoeherTieferAnleitung,
  bangAnleitung } from '../spielanleitungen'
import ReactGA from 'react-ga'
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const Spiele = () => {
  const dispatch = useDispatch();
  const openAnleitung = (e) => {
    var target = e.target
    var text = target.getAttribute('value')
    dispatch(showModal1(e.target.value || text))
  }
  const googleAnalyticsNochnie = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Zur Ich-hab-noch-nie-Seite wechseln'
    })
  }
  const googleAnalyticsEher = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Zur Wer-würde-eher-Seite wechseln'
    })
  }
  const googleAnalyticsWahrheitPflicht = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Zur Wahrheit-oder-Pflicht-Seite wechseln'
    })
  }
  const googleAnalyticsBusfahrer = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Zur Busfahrer-Seite wechseln'
    })
  }
  const googleAnalyticsWerBinIch = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Zur Wer-bin-ich-Seite wechseln'
    })
  }
  const googleAnalyticsHoeherTiefer = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Zur Höher-oder-Tiefer-Seite wechseln'
    })
  }
  const googleAnalyticsBang = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Zur Bang-Seite wechseln'
    })
  }

  return (
    <div>
      <Container className="page-content-bg">
        <Row className="pt-3">
          <Col className="">
            <Row>
              <Col className="col col-4 mb-3">
                <div className="homepage-cards">
                  <h5>Ich hab noch nie!</h5>
                  <Row className="mt-4">
                    <Col>
                      <Button href="/nochnie" onClick={googleAnalyticsNochnie} className="btn btn-bg-play width-100">
                        <PlayArrowIcon />
                      </Button>
                    </Col>
                    <Col>
                      <Button className="btn btn-bg-anleitung width-100" value={ichHabNochNieAnleitung} onClick={openAnleitung}>
                        <InfoOutlinedIcon name="svg" value={ichHabNochNieAnleitung} />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col className="col col-4 mb-3">
                <div className="homepage-cards">
                  <h5>Wer würde eher?</h5>
                  <Row className="mt-4">
                    <Col>
                      <Button href="/eher" onClick={googleAnalyticsEher} className="btn btn-bg-play  width-100">
                        <PlayArrowIcon />
                      </Button>
                    </Col>
                    <Col>
                      <Button className="btn btn-bg-anleitung width-100" value={werWuerdeEherAnleitung} onClick={openAnleitung}>
                        <InfoOutlinedIcon name="svg" value={werWuerdeEherAnleitung} />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col className="col col-4 mb-3">
                <div className="homepage-cards">
                  <h5>Wahrheit oder Pflicht?</h5>
                  <Row className="mt-4">
                    <Col>
                      <Button href="/wahrheitpflicht" onClick={googleAnalyticsWahrheitPflicht} className="btn btn-bg-play  width-100">
                        <PlayArrowIcon />
                      </Button>
                    </Col>
                    <Col>
                      <Button className="btn btn-bg-anleitung width-100" value={wahrheitOderPflichtAnleitung} onClick={openAnleitung}>
                        <InfoOutlinedIcon name="svg" value={wahrheitOderPflichtAnleitung} />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col className="col col-4 mb-3">
                <div className="homepage-cards">
                  <h5>Busfahrer</h5>
                  <Row className="mt-4">
                    <Col>
                      <Button href="/busfahrer" onClick={googleAnalyticsBusfahrer} className="btn btn-bg-play  width-100">
                        <PlayArrowIcon />
                      </Button>
                    </Col>
                    <Col>
                      <Button className="btn btn-bg-anleitung width-100" value={busfahrerAnleitung} onClick={openAnleitung}>
                        <InfoOutlinedIcon name="svg" value={busfahrerAnleitung} />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col className="col col-4 mb-3">
                <div className="homepage-cards">
                  <h5>Wer bin ich?</h5>
                  <Row className="mt-4">
                    <Col>
                      <Button href="/werbinich" onClick={googleAnalyticsWerBinIch} className="btn btn-bg-play  width-100">
                        <PlayArrowIcon />
                      </Button>
                    </Col>
                    <Col>
                      <Button className="btn btn-bg-anleitung width-100" value={werBinIchAnleitung} onClick={openAnleitung}>
                        <InfoOutlinedIcon name="svg" value={werBinIchAnleitung} />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col className="col col-4 mb-3">
                <div className="homepage-cards">
                  <h5>Höher oder Tiefer?</h5>
                  <Row className="mt-4">
                    <Col>
                      <Button href="/hoehertiefer" onClick={googleAnalyticsHoeherTiefer} className="btn btn-bg-play  width-100">
                        <PlayArrowIcon />
                      </Button>
                    </Col>
                    <Col>
                      <Button className="btn btn-bg-anleitung width-100" value={hoeherTieferAnleitung} onClick={openAnleitung}>
                        <InfoOutlinedIcon name="svg" value={hoeherTieferAnleitung} />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col className="col col-4 mb-3">
                <div className="homepage-cards">
                  <h5>BANG!</h5>
                  <Row className="mt-4">
                    <Col>
                      <Button href="/bang" onClick={googleAnalyticsBang} className="btn btn-bg-play  width-100">
                        <PlayArrowIcon />
                      </Button>
                    </Col>
                    <Col>
                      <Button className="btn btn-bg-anleitung width-100" value={bangAnleitung} onClick={openAnleitung}>
                        <InfoOutlinedIcon name="svg" value={bangAnleitung} />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default Spiele