import React, { useContext, useRef } from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { showModal3 } from '../slices/modalSlice';
import { changeNamen } from '../slices/socketSlice';
import { SocketContext } from '../context/socket';
import { BrowserView, MobileView } from 'react-device-detect';

const SpielraumModal3 = () => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const { modal3 } = useSelector(state => state.modal)
  const { roomId, namen, speichernFuer } = useSelector((state) => state.socket)
  const textInput = useRef()

  const namenSpeichern = () => {
    const data = {
      roomId: roomId,
      werbinich: namen,
      username: speichernFuer,
    }
    socket.emit('namenSpeichern', data)
    dispatch(showModal3())
    dispatch(changeNamen(''))
  }

  return (
    <div>
      <Modal
        show={modal3}
        onShow={() => { textInput.current.focus() }}
        onHide={() => dispatch(showModal3())}
        centered
      >
        <BrowserView>
          <Modal.Header closeButton>
            <Modal.Title>
              Wähle Namen für {speichernFuer}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className=''>
                <Form.Control ref={textInput} type="text" value={namen} onChange={(e) => dispatch(changeNamen(e.target.value))} placeholder="Namen eingeben" />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={!(namen.length > 0)}
              onClick={() => namenSpeichern()}
              className='btn btn-bg-primary'>
              Speichern
            </Button>
          </Modal.Footer>
        </BrowserView>
        
        <MobileView>
          <Modal.Header closeButton>
            <Modal.Title>
              Wähle Namen für {speichernFuer}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className=''>
                <Form.Control ref={textInput} type="text" value={namen} onChange={(e) => dispatch(changeNamen(e.target.value))} placeholder="Namen eingeben" />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={!(namen.length > 0)}
              onTouchStart={() => namenSpeichern()}
              className='btn btn-bg-primary'>
              Speichern
            </Button>
          </Modal.Footer>
        </MobileView>
      </Modal>
    </div>
  )
}
export default SpielraumModal3