export default function makeid(length) {
    var result           = ''
    // var result2          = ''
    var characters       = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz123456789ÄäÖöÜüß=+!?*#/_:-%$'
    var charactersLength = characters.length
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength))
    //    result2 += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return (result)
}