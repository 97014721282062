
import React from "react";
import io from "socket.io-client";

export const socket = io("https://socket-backend-trinkspielplatz.herokuapp.com", { 
  transports: ["websocket", "polling"],
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 99999 
});
// export const socket = io("http://localhost:8000", { 
//   transports: ["websocket", "polling"],
//   reconnection: true,
//   reconnectionDelay: 1000,
//   reconnectionDelayMax: 5000,
//   reconnectionAttempts: 99999 
//});
export const SocketContext = React.createContext();