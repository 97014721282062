import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: "idle",
  modal1: false,
  modal2: false,
  modal3: false,
  modal4: false,
  modal5: false,
  modal6: false,
  modalText: {}
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
      showModal1(state, action) {
        state.modal1 = !state.modal1
        state.modal2 = false
        state.modal3 = false
        state.modal4 = false
        state.modalText = action.payload
      },
      showModal2(state, action) {
        state.modal1 = false
        state.modal2 = !state.modal2
        state.modal3 = false
        state.modal4 = false
        state.modalText = action.payload
      },
      showModal3(state, action) {
        state.modal1 = false
        state.modal2 = false
        state.modal3 = !state.modal3
        state.modal4 = false
        state.modalText = action.payload
      },
      showModal4(state, action) {
        state.modal1 = false
        state.modal2 = false
        state.modal3 = false
        state.modal4 = !state.modal4
        state.modalText = action.payload
      },
      showModal5(state, action) {
        state.modal5 = action.payload
      },
      showModal6(state, action) {
        state.modal6 = action.payload
      }
  },
})

export const {
  showModal1,
  showModal2,
  showModal3,
  showModal4,
  showModal5,
  showModal6,
} = modalSlice.actions

export default modalSlice.reducer