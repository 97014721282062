import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { host } from '../constants';
import ReactGA from 'react-ga'

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true

const initialState = {
  status: "idle",
  name: "",
  email: "",
  betreff: "",
  nachricht: "",
};



export const sendMail = createAsyncThunk('kontakt/sendMail', async (nothing, thunkAPI) => {
  const state = thunkAPI.getState()
  const mailInfo = {
    "name": state.kontakt.name,
    "email": state.kontakt.email,
    "betreff": state.kontakt.betreff,
    "nachricht": state.kontakt.nachricht,
  }
  const message = 
  await axios.post(`${host}/trinkspiele/email/`, mailInfo, null) //{
  //await axios.post(`http://localhost:8000/trinkspiele/email/`, mailInfo, null)//{
    //headers: {
      // 'Server': 'gunicorn'
    //}
  //})
    .then((response) => thunkAPI.fulfillWithValue(response.mailInfo))
    .catch((error) => thunkAPI.rejectWithValue(error.response.mailInfo))
  return message;
})


export const kontaktSlice = createSlice({
  name: "kontakt",
  initialState,
  reducers: {
    changeMailInfo: {
      reducer(state, action) {
        const { name, value } = action.payload
        state[name] = value;
      },
      prepare(name, value) {
        return {
          payload: { name, value }
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendMail.fulfilled, (state, action) => {
        ReactGA.event({
          category: 'Button',
          action: 'Nächste Frage bei Ich-hab-noch-nie'
        })
        toast.success("Die Nachricht wurde verschickt")
        state.status = "succeeded";
        state.name = initialState.name
        state.email = initialState.email
        state.betreff = initialState.betreff
        state.nachricht = initialState.nachricht
      })
      .addCase(sendMail.rejected, (state) => {
        toast.error("Etwas hat beim Versenden nicht funktioniert")
        state.status = "idle";
      })
  }
})

export const {
  changeMailInfo
} = kontaktSlice.actions

export default kontaktSlice.reducer