export const ichHabNochNieAnleitung = 
// eslint-disable-next-line 
"Ein Spieler liest die angezeigte Frage vor. \
Alle Spieler, die die Frage mit \"ja, habe ich schon mal\" beantworten können, \
trinken einen Schluck."

export const werWuerdeEherAnleitung = 
// eslint-disable-next-line 
"Ein Spieler liest die angezeigte Frage vor. \
Die Spieler überlegen auf wen der Mitspieler \
die Frage am ehesten zutrifft. Sie zeigen auf den \
jeweiligen Spieler und dieser trinkt die Anzahl \
an Schlücken von seinem Drink. Jeder kann auf \
einen beliebigen Spieler zeigen."

export const wahrheitOderPflichtAnleitung = 
// eslint-disable-next-line 
"Der Spieler an der Reihe entscheidet sich \
zwischen Wahrheit oder Pflicht und tippt \
auf das Feld. Beantwortet die Person die Frage oder \
macht die Aufgabe, darf ein Schluck vergeben werden. \
Wird es nicht gemacht tippt man auf den Bier-Button \
und trinkt die angezeigte Anzahl an Schlucke."

export const busfahrerAnleitung = 
// eslint-disable-next-line 
""

export const werBinIchAnleitung = 
// eslint-disable-next-line 
"Du eine kannst eine Gruppe erstellen oder einer Gruppe \
beitreten. Um einer Gruppe beizutreten, gib die Raum-ID einer \
Gruppe ein und bestätige. Wenn du Ersteller einer Gruppe bist \
kannst du auswählen, ob zufällig Personen für die Spieler \
zugeteilt werden oder die Spieler sich untereinander Personen \
zuteilen können. Danach kann der erste Spieler anfangen Ja-Nein-Fragen \
zu stellen, um seine zugeteilte Person zu erraten. Wird eine \
Frage mit Ja beantwortet, kann der Spieler eine weitere Frage \
stellen. Wird eine Frage mit Nein beantwortet, trinkt der \
Spieler 2 Schlucke und der nächste Spieler ist an der Reihe."

export const hoeherTieferAnleitung = 
// eslint-disable-next-line 
"Der Spieler an der Reihe entscheidet, ob die nächste Karte \
höher, gleich oder tiefer ist, als die angezeigte Karte. \
Ist der Versuch falsch muss der Spieler einen Schluck trinken. \
Er oder sie rät so oft, bis 3 Versuche in Folge richtig sind. \
Dann ist der nächste Spieler dran. Wird bei einem Versuch \
\"Gleich\" ausgewählt und dieser ist richtig, trinken alle \
anderen Spieler ihr Getränk auf ex."

export const bangAnleitung = 
// eslint-disable-next-line 
"Der Spieler, der verliert trinkt die angezeigte Anzahl an Schlucken. \
Der Spieler, der zu früh schießt, trinkt die angezeigt Anzahl an \
Schlucken"