import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Container, Row, Image } from 'react-bootstrap'
import { fetchAllWahrheit, fetchAllPflicht, setNextWahrheit, setNextPflicht, changeVisibilityWahrheit, changeVisibilityPflicht, chooseTrinkanzahl, changeVisibilityTrinkanzahl } from '../slices/fragenSlice'
import { BrowserView, MobileView } from 'react-device-detect'
import ReactGA from 'react-ga'
import image from '../images/biericon.png'

const WahrheitPflicht = () => {
  const dispatch = useDispatch();
  const { status, status2 } = useSelector((state) => state.fragen)
  if (status === 'idle') {
    dispatch(fetchAllWahrheit())
    dispatch(fetchAllPflicht())
  }
  const { wahrheit, pflicht, visibleWahrheit, visibleWahrheit2, visiblePflicht, visiblePflicht2, visibleTrinkanzahl, trinkanzahl, disable } = useSelector((state) => state.fragen)
  const weiterWahrheitPflicht = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Nächste Frage bei Wahrheit-oder-Pflicht'
    })
    dispatch(setNextWahrheit(1))
    dispatch(setNextPflicht(1))
  }
  const waehleTrinkanzahl = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Wähle Anzahl Schlucke zum Trinken'
    })
    dispatch(chooseTrinkanzahl())
    dispatch(changeVisibilityTrinkanzahl())
  }

  return (
    <Container className="col col-8 page-content-bg">
      <BrowserView>
        <Row className="pt-3">
          <Col>
            <div className="spiel-cards-wahrheit-pflicht">
              {status === 'loading' || status2 === 'loading' ?
                <div className="spinner-border loading mt-5" role="status">
                </div>
                :
                <div>
                  <div hidden={visibleTrinkanzahl} className="trinkaufforderung vertical-align">
                    <p className="fontSizeLarger-200 fontWeightLighter">Trinke {trinkanzahl} Schlucke !</p>
                  </div>
                  <div className={visibleWahrheit ? "" : "hidden-wahrheit"} onClick={!visiblePflicht ? () => dispatch(changeVisibilityWahrheit()) : () => { }}>
                    <p hidden={visibleWahrheit} className="height140 fontSizeLarger fontWeightLighter wahrheit-font">Wahrheit</p>
                    <p hidden={visibleWahrheit2} className="pt-3 height140 fontSizeLarger fontWeightLighter">{wahrheit.text}</p>
                  </div>
                  <div disabled className={visiblePflicht ? "" : "hidden-pflicht"} onClick={!visibleWahrheit ? () => dispatch(changeVisibilityPflicht()) : () => { }}>
                    <p hidden={visiblePflicht} className="mt-4 height140 fontSizeLarger fontWeightLighter pflicht-font">Pflicht</p>
                    <p hidden={visiblePflicht2} className="mt-4 pt-3 height140 fontSizeLarger fontWeightLighter">{pflicht.text}</p>
                  </div>
                  <Button onClick={weiterWahrheitPflicht} disabled={disable} className="mt-3 btn btn-bg-primary width-8 float-left" size="lg">Weiter</Button>
                  <Button onClick={visibleTrinkanzahl ? waehleTrinkanzahl : () => { }} disabled={disable} className="mt-3 btn btn-bierbutton width-3 float-right" size="lg"><Image className="bierbutton" src={image} /></Button>
                </div>
              }
            </div>
          </Col>
        </Row>
      </BrowserView>

      <MobileView>
        <Row className="pt-3">
          <Col>
            <div className="spiel-cards-wahrheit-pflicht">
              {status === 'loading' || status2 === 'loading' ?
                <div className="spinner-border loading mt-5" role="status">
                </div>
                :
                <div>
                  <div hidden={visibleTrinkanzahl} className="trinkaufforderung vertical-align">
                    <p className="fontSizeLarger-200 fontWeightLighter">Trinke {trinkanzahl} Schlucke !</p>
                  </div>
                  <div className={visibleWahrheit ? "" : "hidden-wahrheit"} onTouchStart={!visiblePflicht ? () => dispatch(changeVisibilityWahrheit()) : () => { }}>
                    <p hidden={visibleWahrheit} className="height140 fontSizeLarger fontWeightLighter wahrheit-font">Wahrheit</p>
                    <p hidden={visibleWahrheit2} className="pt-3 height140 fontSizeLarger fontWeightLighter">{wahrheit.text}</p>
                  </div>
                  <div disabled className={visiblePflicht ? "" : "hidden-pflicht"} onTouchStart={!visibleWahrheit ? () => dispatch(changeVisibilityPflicht()) : () => { }}>
                    <p hidden={visiblePflicht} className="mt-4 height140 fontSizeLarger fontWeightLighter pflicht-font">Pflicht</p>
                    <p hidden={visiblePflicht2} className="mt-4 pt-3 height140 fontSizeLarger fontWeightLighter">{pflicht.text}</p>
                  </div>
                  <Button onTouchStart={weiterWahrheitPflicht} disabled={disable} className="mt-3 btn btn-bg-primary width-8 float-left" size="lg">Weiter</Button>
                  <Button onTouchStart={visibleTrinkanzahl ? waehleTrinkanzahl : () => { }} disabled={disable} className="mt-3 btn btn-bierbutton width-3 float-right" size="lg"><Image className="bierbutton" src={image} /></Button>
                </div>
              }
            </div>
          </Col>
        </Row>
      </MobileView>
    </Container>
  )
}
export default WahrheitPflicht